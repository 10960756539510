import { cloneDeep, isEqual } from "lodash";
import { Metric } from "web-vitals";

import { ValidateWithJsonSchema } from "@orbits/lib-commands";

import {
  en,
  DEFAULT_ERROR_MSG,
  MISSING_PASSWORD_ERROR_MSG,
  PASSWORD_SHOULD_BE_AT_LEAST_8_CHARS_ERROR_MSG,
  INVALID_EMAIL_ERROR_MSG,
  PASSWORD_SHOULD_BE_AT_LEAST_ONE_LOWERCASE_CHARACTER,
  PASSWORD_SHOULD_BE_AT_LEAST_ONE_NUMBER,
  PASSWORD_SHOULD_BE_AT_LEAST_ONE_SPECIAL_CHARACTER,
  PASSWORD_SHOULD_BE_AT_LEAST_ONE_UPPERCASE_CHARACTER,
  USERNAME_TAKEN_ERROR_MSG,
  INVALID_USERNAME_OR_PASSWORD_ERROR_MSG,
  EXPIRED_CODE_OTP_ERROR_MSG,
  EXPIRED_CODE_ERROR_MSG_1,
  EXPIRED_CODE_ERROR_MSG_2,
  EXPIRED_CODE_ERROR_MSG_3,
  EXPIRED_CODE_ERROR_MSG_4,
  EXPIRED_CODE_ERROR_MSG_5,
  CODE_MISMATCH_EXCEPTION_1,
  CODE_MISMATCH_EXCEPTION_2,
  CODE_MISMATCH_EXCEPTION_3,
  NETWORK_ERROR_MSG,
  MISSING_USERNAME_ERROR_MSG,
  USERNAME_CANT_BE_EMAIL_ADDRESS_ERROR_MSG,
  INVALID_USERNAME_WITH_SPACE_ERROR_MSG,
  MYCONTENT_BASE_ROUTE_TEXT,
  UPLOADS_ROUTE_TEXT,
  AUTH_BASE_ROUTE_TEXT,
  LOGIN_ROUTE_TEXT,
} from "./i18n";
import {
  OrbitsConfig,
  OrbitsConfigSchema,
  OrbitsAppContext,
  StorageWithTTL,
  LocalStorageAppState,
  AppState,
  defaultQuizResult,
  defaultQuizResultTopic,
  QuizResultTopic,
  QuizResultTopicAggregate,
} from "./types";

/**
 *
 * @param detail
 *
 *
 * detail.name could be any one of the following: LCP, FID, CLS
 *
 * - Largest Contentful Paint (LCP): measures loading performance.
 *    To provide a good user experience, LCP should occur within 2.5 seconds of when the page first starts loading.
 *
 * - First Input Delay (FID): measures interactivity.
 *    To provide a good user experience, pages should have a FID of less than 100 milliseconds.
 *
 * - Cumulative Layout Shift (CLS): measures visual stability.
 *    To provide a good user experience, pages should maintain a CLS of less than 0.1.
 *
 * See following for more info:
 *
 * - https://web.dev/vitals/
 * - https://github.com/GoogleChrome/web-vitals/#types
 */
export function sendPerfEvents(
  pagePath: string,
  detail: Metric,
  eventApi?: OrbitsAppContext["eventApi"]
) {
  console.log(`${detail.name}: ${detail.value}`);

  if (detail.name !== "LCP") return;

  if (!pagePath) {
    console.log("Missing one of the required values for sendPerfEvents: ", {
      pagePath,
    });
    return;
  }
  if (eventApi) {
    eventApi({
      detailType: "ORBITS Page Loaded",
      detail: { pagePath, loadTime: detail.value },
    });
  }
}

// src: https://github.com/liesislukas/localstorage-ttl/blob/master/index.js
class LocalStorageWithTTL implements StorageWithTTL<LocalStorageAppState> {
  // default TTL is 5 minutes = 300,000 milliseconds
  constructor(
    private readonly identifier: string,
    private readonly ttlMs: number = 300000
  ) {
    if (!identifier) {
      throw new Error("identifier is required");
    }
  }
  set(value: LocalStorageAppState) {
    try {
      const data = {
        value: value,
        expires_at: new Date().getTime() + this.ttlMs / 1,
      };
      localStorage.setItem(this.identifier, JSON.stringify(data));
    } catch (e) {
      console.error(e);
    }
  }
  get() {
    try {
      const data = JSON.parse(String(localStorage.getItem(this.identifier)));
      if (data) {
        if (data.expires_at && data.expires_at < new Date().getTime()) {
          localStorage.removeItem(this.identifier);
          return undefined;
        } else {
          return data.value;
        }
      }
      return undefined;
    } catch (e) {
      // console.error(e);
      return undefined;
    }
  }
  clear() {
    localStorage.removeItem(this.identifier);
  }
}

const orbitsStateKey = "orbits-state";
export const localStorageWithTTL = new LocalStorageWithTTL(
  orbitsStateKey,
  8.64e7
); // persist the state for a day

export function validatePassword(value: string): string {
  if (!value) {
    return en[MISSING_PASSWORD_ERROR_MSG];
  } else if (value.length < 8) {
    return en[PASSWORD_SHOULD_BE_AT_LEAST_8_CHARS_ERROR_MSG];
  } else if (!/[\d]{1}/g.test(value)) {
    return en[PASSWORD_SHOULD_BE_AT_LEAST_ONE_NUMBER];
  } else if (!/[a-z]{1}/g.test(value)) {
    return en[PASSWORD_SHOULD_BE_AT_LEAST_ONE_LOWERCASE_CHARACTER];
  } else if (!/[A-Z]{1}/g.test(value)) {
    return en[PASSWORD_SHOULD_BE_AT_LEAST_ONE_UPPERCASE_CHARACTER];
  } else if (!/[^a-zA-Z0-9]{1}/g.test(value)) {
    return en[PASSWORD_SHOULD_BE_AT_LEAST_ONE_SPECIAL_CHARACTER];
  }
  return "";
}

export function validateUsername(value: string, ignoreEmail: boolean): string {
  if (!value) {
    return en[MISSING_USERNAME_ERROR_MSG];
  } else if (value.split(/\s/).length > 1) {
    return en[INVALID_USERNAME_WITH_SPACE_ERROR_MSG];
  } else if (!ignoreEmail && value.includes("@")) {
    return en[USERNAME_CANT_BE_EMAIL_ADDRESS_ERROR_MSG];
  }
  return "";
}

// src: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export function validateEmail(email: string): string {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email.toLowerCase())) {
    return en[INVALID_EMAIL_ERROR_MSG];
  }
  return "";
}

export function getAuthErrorMessage(
  e: unknown,
  authFunctionName?: string
): JSX.Element | undefined {
  const { code, message } = e as { code: string; message: string };
  console.log(`${code}: ${message}`);
  console.error((e as { stack: string }).stack);

  // CommonErrors: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/CommonErrors.html
  // InitiateAuth: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
  // SignUp: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html
  // ConfirmSignUp: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmSignUp.html
  // ForgotPassword: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html
  // ConfirmForgotPassword: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmForgotPassword.html
  // ChangePassword: https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ChangePassword.html
  if (
    "UsernameExistsException" === code ||
    ("UserLambdaValidationException" === code &&
      message.startsWith("PreSignUp failed with error User with username")) // UserLambdaValidationException: PreSignUp failed with error User with username username_1 already exists.
  ) {
    return <>{en[USERNAME_TAKEN_ERROR_MSG]}</>;
  } else if (
    ["UserNotConfirmedException", "PasswordResetRequiredException"].includes(
      code
    )
  ) {
    return undefined;
  } else if (
    ["UserNotFoundException", "NotAuthorizedException"].includes(code)
  ) {
    return <>{en[INVALID_USERNAME_OR_PASSWORD_ERROR_MSG]}</>;
  } else if (code === "ExpiredCodeException") {
    if (authFunctionName === "createAccountSubmitOTP") {
      return <>{en[EXPIRED_CODE_OTP_ERROR_MSG]}</>;
    } else {
      return (
        <>
          {en[EXPIRED_CODE_ERROR_MSG_1]}
          <strong>{en[EXPIRED_CODE_ERROR_MSG_2]}</strong>
          {en[EXPIRED_CODE_ERROR_MSG_3]}
          <strong>{en[EXPIRED_CODE_ERROR_MSG_4]}</strong>
          {en[EXPIRED_CODE_ERROR_MSG_5]}
        </>
      );
    }
  } else if (code === "CodeMismatchException") {
    return (
      <>
        {en[CODE_MISMATCH_EXCEPTION_1]}
        <strong>{en[CODE_MISMATCH_EXCEPTION_2]}</strong>
        {en[CODE_MISMATCH_EXCEPTION_3]}
      </>
    );
  } else if (code === "NetworkError") {
    return <>{en[NETWORK_ERROR_MSG]}</>;
  } else {
    return <>{en[DEFAULT_ERROR_MSG]}</>;
  }
}

export const authBasePath = "/" + en[AUTH_BASE_ROUTE_TEXT];
export const authLoginPath = authBasePath + "/" + en[LOGIN_ROUTE_TEXT];
export const myContentBasePath = "/" + en[MYCONTENT_BASE_ROUTE_TEXT];
export const uploadsPath = "/" + en[UPLOADS_ROUTE_TEXT];

// const myContentVideosPath =
//   myContentBasePath +
//   "/" +
//   en[VIDEO_ROUTE_TEXT] +
//   "/:" +
//   en[SELECTED_VIDEO_ID_ROUTE_TEXT];

// const dashboardPath = "/dashboard";
// const studySpacePath = "/study-space";

// console.info({
//   authBasePath,
//   authLoginPath,
//   myContentBasePath,
//   myContentVideosPath,
//   uploadsPath,
//   dashboardPath,
//   studySpacePath,
// });

// const isVideoIdfetchable = (videoId: string | undefined): boolean =>
//   typeof videoId === "string" && validateUuid(videoId);

// const isUploadingVideoId = (videoId: string | undefined): boolean =>
//   videoId === en[UPLOADING_VIDEO_ID];

// const isVideoIdValid = (videoId: string | undefined): boolean =>
//   isVideoIdfetchable(videoId) || isUploadingVideoId(videoId);

// const createMyContentVideosPath = (videoId: string | undefined): string => {
//   if (!isVideoIdValid(videoId)) {
//     console.log("Invalid videoId", videoId);
//   }
//   return myContentBasePath + `/${en[VIDEO_ROUTE_TEXT]}/${videoId}`;
// };

export function validateResponse<ResponseType>(
  schema: object,
  input: unknown
): ResponseType {
  new ValidateWithJsonSchema(schema).execute(input);
  return input as ResponseType;
}

export const calculateKnowledgePercentageByTopic = (
  quizResults: QuizResultTopic[]
): QuizResultTopicAggregate =>
  quizResults.reduce((acc: QuizResultTopic, curr: QuizResultTopic) => {
    Object.entries(curr.topicAggregate).map(([topic, result]) => {
      if (!acc.topicAggregate[topic]) {
        acc.topicAggregate[topic] = cloneDeep(defaultQuizResult);
      }
      acc.topicAggregate[topic].questionsCount += result.questionsCount;
      acc.topicAggregate[topic].answeredCount += result.answeredCount;
      acc.topicAggregate[topic].answeredCorrectlyCount +=
        result.answeredCorrectlyCount;
    });
    return acc;
  }, cloneDeep(defaultQuizResultTopic)).topicAggregate;

export const calculateUpdatedStateKeys = (
  previousState: AppState,
  nextState: AppState
) => {
  const previousStateKeys: Array<keyof AppState> = Object.keys(
    previousState
  ) as Array<keyof AppState>;
  return previousStateKeys.filter(
    (key: keyof AppState) => !isEqual(previousState[key], nextState[key])
  );
};

export function shuffleArray<T>(array: T[]): T[] {
  return array.sort(() => Math.random() - 0.5);
}

const OrbitsConfigValidator = (() => {
  try {
    console.warn = () => {};
    return new ValidateWithJsonSchema(OrbitsConfigSchema);
  } catch (e) {
    // console.error(e);
    return undefined;
  }
})();

export function getOrbitsConfig(): OrbitsConfig | undefined {
  try {
    console.warn = () => {};
    OrbitsConfigValidator?.execute(window["config"] as unknown);
    return window["config"] as OrbitsConfig;
  } catch (e) {
    // console.log(
    //   "message" in (e as { message?: string })
    //     ? (e as { message: string }).message
    //     : e
    // );
    // throw e;
    return undefined;
  }
}

// const START_SEPRATOR = "*#*";
// const END_SEPRATOR = "*%*";

const sepreatorRe = /\*#\*|\*%\*/i;

type parseHighlightsReturnType = {
  str: string;
  highlighted: boolean;
}[];

export function parseHighlights(
  inStr: string | undefined,
  query: string
): parseHighlightsReturnType {
  const returnValue: parseHighlightsReturnType = [];
  if (!inStr) {
    return returnValue;
  }

  const lowerCaseQuery = query.toLowerCase();

  const chunks = inStr.split(sepreatorRe);

  chunks.map(
    (str) =>
      str &&
      returnValue.push({
        str,
        highlighted: str.toLowerCase() === lowerCaseQuery,
      })
  );

  return returnValue;
}
