import React, { useState } from "react";

import { Button, TextField, Typography } from "../styledComponents";
import { validatePassword } from "../utils";
import ErrorBoundary from "./ErrorBoundary";
import {
  CANCEL_BUTTON_LABEL,
  CHANGE_PASSWORD_BUTTON_LABEL,
  CHANGE_YOUR_PASSWORD_HEADING,
  CONFIRMATION_CODE_FILED_LABEL,
  en,
  FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT,
  FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT_2,
  INVALID_CODE_ERROR_MSG,
  MISSING_CODE_ERROR_MSG,
  NEW_PASSWORD_FILED_LABEL,
  RECOVER_YOUR_PASSWORD_HEADING,
  STEP_2_OF_2_LABEL,
} from "../i18n";
import { LoginRegisterClassesType } from "../types";
import { useStyles } from "../LoginRegister";

export type ForgotPasswordSubmitPropsType = {
  changeForgottenPassword: (code: string, newPassword: string) => void;
  isPasswordResetRequired: boolean;
  cancel: () => void;
};

export default function (props: ForgotPasswordSubmitPropsType) {
  const classes: LoginRegisterClassesType = useStyles();
  const { changeForgottenPassword, isPasswordResetRequired, cancel } = props;

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const validateAndSetCode = (value: string) => {
    setCodeError("");
    if (!value) {
      setCodeError(en[MISSING_CODE_ERROR_MSG]);
    } else if (value.length !== 6) {
      setCodeError(en[INVALID_CODE_ERROR_MSG]);
    }
    setCode(value);
  };

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const validateAndSetNewPassword = (value: string) => {
    setNewPasswordError("");
    setNewPasswordError(validatePassword(value));
    setNewPassword(value);
  };

  const _changeForgottenPassword = () => {
    validateAndSetCode(code);
    changeForgottenPassword(code, newPassword);
  };

  return (
    <ErrorBoundary>
      <Typography color="primary" variant="h3">
        {isPasswordResetRequired
          ? en[CHANGE_YOUR_PASSWORD_HEADING]
          : en[RECOVER_YOUR_PASSWORD_HEADING]}
      </Typography>
      {isPasswordResetRequired || (
        <Typography color="primary" variant="h4">
          {en[STEP_2_OF_2_LABEL]}
        </Typography>
      )}
      <Typography color="primary" variant="body1">
        {en[FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT]}
      </Typography>
      <Typography color="primary" variant="body1">
        {en[FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT_2]}
      </Typography>
      <TextField
        autoFocus
        className={codeError ? classes.errorField : classes.field}
        fullWidth
        label={en[CONFIRMATION_CODE_FILED_LABEL]}
        value={code}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => validateAndSetCode(e.target.value)}
        error={Boolean(codeError)}
        helperText={codeError}
        autoComplete="one-time-code"
      />
      <TextField
        autoFocus
        className={newPasswordError ? classes.errorField : classes.field}
        fullWidth
        label={en[NEW_PASSWORD_FILED_LABEL]}
        type="password"
        value={newPassword}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => validateAndSetNewPassword(e.target.value)}
        error={Boolean(newPasswordError)}
        helperText={newPasswordError}
        autoComplete="new-password"
      />
      <div style={{ float: "right" }}>
        <Button
          className={classes.btn}
          color="primary"
          variant="outlined"
          onClick={cancel}
        >
          {en[CANCEL_BUTTON_LABEL]}
        </Button>
        <Button
          sx={{ marginLeft: 1 }}
          className={classes.btn}
          color="primary"
          variant="contained"
          onClick={_changeForgottenPassword}
        >
          {en[CHANGE_PASSWORD_BUTTON_LABEL]}
        </Button>
      </div>
    </ErrorBoundary>
  );
}
