import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";

import { colors } from "../themes";
import { getTopics } from "./QuizData";

export function Filter({
  eleKey,
  label,
  options,
  setSelection,
}: {
  eleKey: string;
  label: string;
  options: string[];
  setSelection?: (newSelection: string[]) => void;
}) {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [selectionState, setSelectionState] = React.useState<{
    [topic: string]: boolean;
  }>(() =>
    options.reduce((acc, cur) => {
      acc[cur] = true;
      return acc;
    }, {} as { [topic: string]: boolean })
  );
  React.useEffect(
    () =>
      setSelection &&
      setSelection(
        Object.keys(selectionState).filter((topic) => selectionState[topic])
      ),
    [selectionState]
  );
  return (
    <Box
      key={eleKey}
      sx={{
        display: "flex",
        p: 1,
        m: 1,
        flexGrow: 1,
        flexDirection: "column",
        border: "black 1px solid",
        borderRadius: "4px",
        backgroundColor: colors.lightestGrey,
        color: "black",
        height: "max-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        <div
          onClick={() =>
            isExpanded ? setIsExpanded(false) : setIsExpanded(true)
          }
          style={{ width: "100%" }}
        >
          <Typography
            variant="body1"
            color="black"
            sx={{ display: "inline-block" }}
          >
            {label}
          </Typography>
          {isExpanded ? (
            <KeyboardArrowUpOutlinedIcon sx={{ float: "right" }} />
          ) : (
            <KeyboardArrowDownOutlinedIcon sx={{ float: "right" }} />
          )}
        </div>
      </Box>
      {isExpanded && (
        <Box sx={{ display: "block" }}>
          {options.map((option) => (
            <FormControlLabel
              key={option}
              label={option}
              control={<Checkbox checked={selectionState[option]} />}
              // sx={{ width: "100%" }}
              onChange={(_, checked: boolean) =>
                setSelectionState({ ...selectionState, [option]: checked })
              }
            />
          ))}
        </Box>
      )}
    </Box>
  );
}

const topics = getTopics();

export default function ({ subjectName }: { subjectName: string }) {
  const [isActive, setIsActive] = React.useState(false);
  const [selectedTopics, setSelectedTopics] = React.useState<string[]>(topics);

  console.log("ContentSearchBar topics", topics, selectedTopics);

  return isActive ? (
    <>
      <Paper
        sx={{
          position: "fixed",
          border: `2px solid ${colors.primary}`,
          borderRadius: "4px",
          minHeight: "100px",
          zIndex: 1,
          width: "55vw",
          height: "max-content",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 1,
            pb: 2,
            borderBottom: `${colors.grey} 1px solid`,
          }}
        >
          <SearchIcon sx={{ color: "black" }} />
          <TextField
            variant="standard"
            fullWidth
            sx={{ mx: 2, color: "black" }}
            placeholder={`Smart Search across in ${subjectName}`}
          />
          <CloseIcon
            sx={{ color: "black" }}
            data-testid="close-icon"
            onClick={() => setIsActive(false)}
          />
        </Box>
        <Typography variant="body1" color="black" ml={1} mt={2}>
          Filter Results By
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Filter
            eleKey="topic"
            label="All Topics"
            options={topics}
            setSelection={setSelectedTopics}
          />
          <Filter
            eleKey="contnet-type"
            label="All Content Types"
            options={["vidoes", "PDFs"]}
            setSelection={setSelectedTopics}
          />
          <Filter
            eleKey="contnet-source"
            label="All Content Sources"
            options={["Study Path", "My Uploads"]}
            setSelection={setSelectedTopics}
          />
        </Box>
        <Box sx={{ height: "32px" }} />
      </Paper>
    </>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h6">Content</Typography>
      <SearchIcon
        color="primary"
        data-testid="search-icon"
        onClick={() => setIsActive(true)}
      />
    </Box>
  );
}
