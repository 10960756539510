import { styled } from "@mui/material/styles";

import {
  Button as MuiButton,
  Divider as MuiDivider,
  Link as MuiLink,
  TextField as MuiTextField,
  Typography as MuiTypography,
} from "@mui/material";

// TODO: see if it is possible to replace most code in this file with addTopMargin function
// function addTopMargin<T extends JSX.Element>(component: T): T {
//   return styled(component)(({ theme }) => ({
//     marginTop: theme.spacing(2)
//   }));
// }

export const Button = styled(MuiButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

// export const ButtonWithLeftMargin = styled(MuiButton)(({ theme }) => ({
//   marginTop: theme.spacing(2),
//   marginleft: theme.spacing(2),
// }));

export const Divider = styled(MuiDivider)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const Link = styled(MuiLink)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

export const TextField = styled(MuiTextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const Typography = styled(MuiTypography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
