import React from "react";
import { Auth } from "aws-amplify";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { AlertColor } from "@mui/lab";
import { cloneDeep, isEqual, throttle, unionBy, debounce } from "lodash";
import axiosRetry, { exponentialDelay } from "axios-retry";
import { SearchCommandOutput } from "@aws-sdk/client-cloudsearch-domain";

import {
  GetVideoTranscriptResponseSchema,
  GetUploadsResponseSchema,
  GetVideoSharesResponseSchema,
  PostVideoResponseSchema,
  StringResponseSchema,
} from "@orbits/lib-videos-api-schemas";
import {
  GetGroupsResponseSchema,
  // GetGroupSharesResponseSchema,
  GetGroupSubgroupingsResponseSchema,
} from "@orbits/lib-groups-api-schemas";

import {
  localStorageWithTTL,
  getOrbitsConfig,
  validateResponse,
  calculateUpdatedStateKeys,
} from "./utils";
import {
  AppState,
  Action,
  AuthState,
  defaultState,
  Group,
  QuizScreen,
  LocalStorageAppState,
  AppStateHistory,
  QuizResultTopic,
  OrbitsEventType,
  GetVideoTranscriptResponse,
  Resource,
  GetUploadsResponse,
  GetGroupSharesResponse,
  GetGroupsResponse,
  StringResponse,
  PostVideoResponse,
  GetGroupSubgroupingsResponse,
  PutEventsResponse,
  PutEventsResponseSchema,
  GetVideoSharesResponse,
  Tree,
  OrbitsAppContext,
  ResourceType,
} from "./types";

const config = getOrbitsConfig();

const axiosInstance = axios.create();

axiosRetry(axiosInstance, {
  retries: 5,
  retryDelay: exponentialDelay,
});

// const defaultCachingTimeMills = 300_000; // 300 seconds = 5 minutes
const authStateCodes = [401, 403];

// const defaultThrottleTime = 5_000; // 5 seconds

let useMockServer = false;
if (config?.endpointV2?.endsWith("/MOCK_SERVER")) {
  config.endpointV2 = config.endpointV2.slice(0, "/MOCK_SERVER".length * -1);
  useMockServer = true;
}
// console.log("useMockServer:", useMockServer);

Auth.configure(config?.awsCognito);

let stateHistory: AppStateHistory = [];

// const isEqualIgnoringThumbnails = (a: LocalStorageAppState, b: LocalStorageAppState) => {
//   Tree.removeAllThumbnails(cloneDeep(a.tree));
//   Tree.removeAllThumbnails(cloneDeep(b.tree));
//   return isEqual(a, b);
// }

function reducerCreator({
  saveTolocalStorage,
}: {
  saveTolocalStorage: boolean;
}) {
  return (state: AppState, action: Action): AppState => {
    // Object.freeze(state);
    // console.debug("state:", state);
    // console.debug(
    //   "action:",
    //   action.type,
    //   "payload" in action ? action.payload : ""
    // );
    state = cloneDeep(state);

    if (
      stateHistory.length &&
      isEqual(action, stateHistory[stateHistory.length - 1].action)
    ) {
      // console.debug("action is the same as the last action, ignoring", action);
      return state;
    }

    switch (action.type) {
      case "NO_OP":
        return state;
      case "LOGIN":
        state = {
          ...state,
          authState: AuthState.LOGGED_IN,
          cognitioUser: action.payload,
        };
        break;
      case "LOGOUT":
        if (saveTolocalStorage) {
          // clear everything when user logs out
          localStorage.clear();
          localStorageWithTTL.set(defaultState);
          stateHistory = [];
        }
        state = {
          ...defaultState,
        };
        break;
      case "NEW_PASSWORD_REQUIRED":
        state = {
          ...state,
          authState: AuthState.NEW_PASSWORD_REQUIRED,
        };
        break;
      case "REFRESH_ID_TOKEN":
        state = {
          ...state,
          authState: AuthState.LOGGED_IN,
          cognitioUser: {
            username: state.cognitioUser?.username || "",
            refreshToken: state.cognitioUser?.refreshToken || "",
            attributes: state.cognitioUser?.attributes || {},
            idToken: action.payload.idToken,
          },
        };
        break;
      case "FORGOT_PASSWORD":
        state = {
          ...state,
          authState: AuthState.FORGOT_PASSWORD,
        };
        break;
      case "FORGOT_PASSWORD_SUBMIT":
        state = {
          ...state,
          authState: AuthState.FORGOT_PASSWORD_SUBMIT,
        };
        break;
      case "REGISTER":
        state = {
          ...state,
          authState: AuthState.REGISTER,
        };
        break;
      case "REGISTER_CONFIRMATION":
        state = {
          ...state,
          authState: AuthState.REGISTER_CONFIRMATION,
        };
        break;
      case "SET_SELECTED_VIDEO_ID":
        state = {
          ...state,
          selectedResourceId: action.payload,
        };
        break;
      case "SET_SELECTED_GROUP_ID":
        state = {
          ...state,
          selectedGroupId: action.payload,
          breadcrumbs: Tree.findGroupPathById(state.tree, action.payload),
          selectedResourceId: "",
        };
        break;
      case "SEEK_VIDEO":
        state = {
          ...state,
          videoAtSeconds: action.payload,
        };
        break;
      case "SET_UPLOADS":
        state = {
          ...state,
          uploads: action.payload.map((upload) => new Resource(upload)),
        };
        break;
      case "OPEN_SNACKBAR":
        state = {
          ...state,
          isSnackbarOpen: true,
          snackbarMessage: action.payload.message,
          snackbarSeverity: action.payload.severity,
        };
        break;
      case "CLOSE_SNACKBAR":
        state = {
          ...state,
          isSnackbarOpen: false,
          snackbarMessage: "",
          snackbarSeverity: "success",
        };
        break;
      case "SET_QUIZ_SCREEN":
        state = {
          ...state,
          quizScreen: action.payload,
        };
        break;
      case "ADD_QUIZ_RESULT":
        state = {
          ...state,
          quizResults: [...state.quizResults, action.payload],
        };
        break;
      case "SET_SHARABLE_GROUPS":
        state = {
          ...state,
          sharableGroups: action.payload,
        };
        break;
      case "START_VIDEO_UPLOAD":
        (action.payload.sharedWith || []).forEach((group: Group) =>
          Tree.addVideos(state.tree, group.id, [
            { ...action.payload, sharedWith: [] },
          ])
        );
        state = {
          ...state,
          uploads: [{ ...action.payload }, ...(state.uploads || [])],
        };
        break;
      case "UPDATE_VIDEO_UPLOAD_PERCENT":
        Tree.updateVideoUploadPercent(
          state.tree,
          action.payload.videoId,
          Math.floor(action.payload.newPercent)
        );
        state = {
          ...state,
          uploads: (state.uploads || []).map((upload) =>
            upload.id === action.payload.videoId
              ? new Resource({
                  ...upload,
                  uploadPercent: Math.floor(action.payload.newPercent),
                })
              : upload
          ),
        };
        break;
      case "ADD_GROUPS_TO_TREE":
        Tree.addGroups(
          state.tree,
          action.payload.groupId,
          action.payload.response.map(({ subgroup }) => subgroup)
        );
        state = {
          ...state,
        };
        break;
      case "ADD_VIDEOS_TO_TREE":
        Tree.addVideos(
          state.tree,
          action.payload.groupId,
          action.payload.response.map((video) => new Resource(video))
        );
        state = {
          ...state,
        };
        break;
      case "ADD_SHARED_WITH_TO_UPLOADS":
        state = {
          ...state,
          uploads: (state.uploads || []).map((upload) => ({
            ...upload,
            sharedWith:
              upload.id === action.payload.videoId
                ? unionBy(
                    [
                      ...(upload.sharedWith || []),
                      ...(action.payload.response?.map((r) => r.group) || []),
                    ],
                    "id"
                  )
                : upload.sharedWith,
          })),
        };
        state = {
          ...state,
        };
        break;
      case "REMOVE_RESOURCES":
        Tree.removeResources(state.tree, action.payload);
        state = {
          ...state,
          uploads: (state.uploads || []).filter(
            (upload) => !action.payload.includes(upload.id)
          ),
        };
        break;
      case "SET_GLOBAL_SEARCH_RESULTS":
        state = {
          ...state,
          globalSearchResults: action.payload,
        };
        break;
      // case "ADD_THUMBNAIL_TO_VIDEO":
      //   Tree.addThumbnail(
      //     state.tree,
      //     action.payload.videoId,
      //     action.payload.response
      //   );
      //   state = {
      //     ...state,
      //     uploads: state.uploads.map((upload) =>
      //       upload.id === action.payload.videoId
      //         ? new Video({
      //             ...upload,
      //             thumbnailUrl: action.payload.response,
      //           })
      //         : upload
      //     ),
      //   };
      //   break;
    }

    if (!state) throw new Error("Invalid state");

    // if (
    //   stateHistory.length &&
    //   isEqual(state, stateHistory[stateHistory.length - 1].state)
    // ) {
    //   // console.debug(
    //   //   "state is equal to last state, not continuing action",
    //   //   state,
    //   //   action
    //   // );
    //   return state;
    // }

    // console.debug("\nnew state:", state, "\n");
    stateHistory.push({
      action,
      state: cloneDeep(state),
      updatedStateKeys: calculateUpdatedStateKeys(
        stateHistory.length
          ? stateHistory[stateHistory.length - 1].state
          : defaultState,
        state
      ),
    });
    // console.log("stateHistory:", stateHistory);
    ((window as unknown) as {
      stateHistory: AppStateHistory;
    }).stateHistory = stateHistory;

    if (saveTolocalStorage) {
      // Only store whitelisted properties in localstorage
      // and trigger localstorage save if the state for localstorage has changed
      const newLocalStorageState: LocalStorageAppState = {
        authState: state.authState,
        cognitioUser: state.cognitioUser,
        quizResults: state.quizResults,
      };

      const previousState: AppState =
        stateHistory.length > 2
          ? stateHistory[stateHistory.length - 2].state
          : defaultState;

      const previousLocalStorageState: LocalStorageAppState = {
        authState: previousState.authState,
        cognitioUser: previousState.cognitioUser,
        quizResults: previousState.quizResults,
      };

      if (!isEqual(newLocalStorageState, previousLocalStorageState)) {
        setTimeout(() => localStorageWithTTL.set(newLocalStorageState), 0); // persist the state for a day
      }
    }

    return state;
  };
}

const reducer = reducerCreator({ saveTolocalStorage: true });

const localStorageState: LocalStorageAppState = localStorageWithTTL.get();

const initState: OrbitsAppContext = cloneDeep({
  ...defaultState,
  ...localStorageState,
}) as OrbitsAppContext;

const AppContext = React.createContext(initState);

const AppContextProvider = ({ children }: { children: React.ReactChild }) => {
  // const history = useHistory();
  const [state, dispatch] = React.useReducer(reducer, initState);
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const loginUser = (payload: AppState["cognitioUser"]) => {
    dispatch({ type: "LOGIN", payload });

    // Pre fetch to speed up the app
    fetchTree();
    fetchUploads();
    fetchShareableGroups();

    eventApi({ detailType: "ORBITS User Logged In" });
  };

  const logoutUser = () => dispatch({ type: "LOGOUT" });

  const seekVideo = (seconds: number, play?: boolean) => {
    dispatch({ type: "SEEK_VIDEO", payload: seconds });
    if (videoRef?.current) {
      videoRef.current.currentTime = seconds;
      // start playing video if play is set to true
      if (play) {
        videoRef.current.play();
      }
    }
  };

  const setSelectedResource = (newVideoId: string) =>
    dispatch({ type: "SET_SELECTED_VIDEO_ID", payload: newVideoId });

  const openSnackbar = (severity: AlertColor, message: string) =>
    dispatch({ type: "OPEN_SNACKBAR", payload: { severity, message } });

  const closeSnackbar = () => dispatch({ type: "CLOSE_SNACKBAR" });

  const getIdToken = () =>
    localStorageWithTTL.get()?.cognitioUser?.idToken ||
    state.cognitioUser?.idToken ||
    "";

  const api: <ResponseType>({
    method,
    path,
    data,
    signedUrl,
    throwError,
    responseSchema,
    videoIndexEndpoint,
    useSmartnEndpoint,
  }: {
    method: "GET" | "POST" | "DELETE";
    path: string;
    data?: unknown;
    signedUrl?: boolean;
    throwError?: boolean;
    responseSchema?: object;
    videoIndexEndpoint?: string;
    useSmartnEndpoint?: boolean;
  }) => {
    cancel: () => void;
    fetch: () => Promise<ResponseType>;
  } = ({
    method,
    path,
    data,
    signedUrl,
    throwError,
    responseSchema,
    videoIndexEndpoint,
    useSmartnEndpoint,
  }) => {
    // https://stackoverflow.com/questions/38329209/how-to-cancel-abort-ajax-request-in-axio
    const ourRequest = axios.CancelToken.source();

    return {
      cancel: () => ourRequest.cancel(),
      fetch: async () => {
        const id = Math.round(Math.random() * 100);
        const endpoint = videoIndexEndpoint
          ? videoIndexEndpoint
          : useSmartnEndpoint
          ? config?.smartnEndpoint
          : config?.endpointV2;
        const url = endpoint + path;
        // const accessToken = state.cognitioUser?.idToken || "";

        if (useMockServer) {
          const mock = await axios({
            method: method,
            url: "http://127.0.0.1:3000" + path,
            headers: {
              Authorization: getIdToken(),
              Forwarded: endpoint,
            },
            cancelToken: ourRequest.token,
          });
          console.log(
            "Mock: ",
            {
              method: method,
              url: "http://127.0.0.1:3000" + path,
              headers: {
                Authorization: getIdToken(),
                Forwarded: endpoint,
              },
              cancelToken: ourRequest.token,
            },
            mock
          );
          return Promise.resolve(mock.data);
        }

        try {
          // Use interceptor to inject the token to requests
          axiosInstance.interceptors.request.use((request) => {
            if (useMockServer) {
              request.headers["Forwarded"] = endpoint;
            }
            if (
              config?.endpointV2 ||
              config?.searchEndpoint ||
              config?.smartnEndpoint
            ) {
              const idToken = getIdToken();
              if (
                request.url?.startsWith(config.endpointV2) ||
                request.url?.startsWith(config.smartnEndpoint)
              ) {
                request.headers["Authorization"] = idToken;
              }
              if (request.url?.startsWith(config.searchEndpoint)) {
                request.headers["Authorization"] = `Bearer ${idToken}`;
                request.headers["x-amz-date"] = new Date().toISOString();
              }
            }
            return request;
          });

          // Function that will be called to refresh authorization
          const refreshAuthLogic = async () => {
            // console.log("Refreshing auth error", error);
            const cognitioUser: CognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession: CognitoUserSession = await Auth.currentSession();
            return new Promise((resolve, reject) =>
              cognitioUser.refreshSession(
                currentSession.getRefreshToken(),
                (err: unknown, result: unknown) => {
                  if (err) {
                    console.error("Error refreshing auth", err);
                    dispatch({ type: "LOGOUT" });
                    return reject();
                  } else if (
                    result &&
                    typeof result === "object" &&
                    "idToken" in result
                  ) {
                    console.log("Result of referesh token", result);
                    const idToken =
                      (result as { idToken: { jwtToken?: string } }).idToken
                        .jwtToken || "";
                    dispatch({
                      type: "REFRESH_ID_TOKEN",
                      payload: { idToken },
                    });
                    // TODO: remove setTimeout
                    setTimeout(() => resolve(idToken), 100); // wait to make sure the state is updated
                    resolve(idToken);
                  } else {
                    console.error("Error refreshing auth : else", result);
                  }
                }
              )
            );
          };

          // Instantiate the interceptor (you can chain it as it returns the axiosInstance instance)
          createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
            statusCodes: authStateCodes, // default: [ 401 ]
          });

          const response = await axiosInstance({
            method,
            url,
            data,
            cancelToken: ourRequest.token,
          });

          const responseData = (signedUrl
            ? await axios.get(response.data)
            : response
          ).data;

          if (responseSchema) {
            try {
              validateResponse(responseSchema, responseData);
            } catch (err) {
              console.error("Error validating response", err);
              return Promise.reject(err);
            }
          }

          return responseData as ResponseType;
        } catch (e) {
          const error = e as {
            message?: string;
            response?: { status?: number; data?: unknown; headers?: unknown };
          };
          console.log(
            `Response Error ${id}: `,
            error.message ? error.message : e
          );

          if (throwError) {
            throw e;
          }
        }
      },
    };
  };

  const resourceIndexApi = debounce(
    async (query: string, type: ResourceType[]) => {
      const payload = await api<SearchCommandOutput>({
        method: "GET",
        path: `/?q="${query}"&t=${encodeURIComponent(
          `["${type.join('", "')}"]`
        )}`,
        videoIndexEndpoint: config?.searchEndpoint,
      }).fetch();

      dispatch({ type: "SET_GLOBAL_SEARCH_RESULTS", payload });
    },
    500
  );

  const eventApi = (event: OrbitsEventType, throwError?: boolean) =>
    api<PutEventsResponse>({
      method: "POST",
      path: "/events",
      data: event,
      throwError,
      responseSchema: PutEventsResponseSchema,
    }).fetch();

  const fetchUploads = throttle(
    () =>
      (async () => {
        const payload = await api<GetUploadsResponse>({
          method: "GET",
          path: "/videos?q=uploads",
          responseSchema: GetUploadsResponseSchema,
          useSmartnEndpoint: true,
        }).fetch();
        dispatch({ type: "SET_UPLOADS", payload });

        // Eagerly fetch shared with groups for uploads
        try {
          await Promise.all(
            payload.map(({ id }) => fetchSharedWithForResourceId(id))
          );
        } catch (e) {
          console.error("Error fetching shared with for uploads", e);
        }
      })(),
    1000,
    { leading: true }
  );

  const fetchGroupShares = (groupId: string) =>
    (async () => {
      const response = await api<GetGroupSharesResponse>({
        method: "GET",
        path: `/groups/${groupId}/shares`,
        // responseSchema: GetGroupSharesResponseSchema,
        useSmartnEndpoint: true,
      }).fetch();
      if (response?.length) {
        dispatch({
          type: "ADD_VIDEOS_TO_TREE",
          payload: { response, groupId },
        });
      }
    })();

  const setSelectedGroup = (group: Group) =>
    dispatch({
      type: "SET_SELECTED_GROUP_ID",
      payload: group.id,
    });

  const setQuizScreen = (quizScreen: QuizScreen) =>
    dispatch({ type: "SET_QUIZ_SCREEN", payload: quizScreen });

  const addQuizResult = (quizResultTopic: QuizResultTopic) =>
    dispatch({ type: "ADD_QUIZ_RESULT", payload: quizResultTopic });

  const deleteVideo = (videoId: string) =>
    api<void>({
      path: `/videos/${videoId}`,
      method: "DELETE",
      throwError: true,
    }).fetch();

  const getTranscript = (videoId: string) =>
    api<GetVideoTranscriptResponse>({
      method: "GET",
      path: `/videos/${videoId}/transcript`,
      signedUrl: true,
      responseSchema: GetVideoTranscriptResponseSchema,
    });

  const shareVideo = (videoId: string, groupId: string) =>
    api<void>({
      method: "POST",
      path: "/shares",
      data: { groupId, videoId },
    }).fetch();

  const createS3Url = (name: string) =>
    api<PostVideoResponse>({
      method: "POST",
      path: "/videos",
      data: { title: name },
      responseSchema: PostVideoResponseSchema,
    }).fetch();

  const fetchShareableGroups = throttle(
    () =>
      (async () => {
        const payload = await api<GetGroupsResponse>({
          method: "GET",
          path: "/groups?q=shareables",
          responseSchema: GetGroupsResponseSchema,
          useSmartnEndpoint: true,
        }).fetch();
        if (payload?.length) {
          dispatch({ type: "SET_SHARABLE_GROUPS", payload });
        }
      })(),
    2000,
    { leading: true, trailing: false }
  );

  const getMedia = (videoId: string) =>
    api<StringResponse>({
      method: "GET",
      path: `/videos/${videoId}/media`,
      responseSchema: StringResponseSchema,
      useSmartnEndpoint: true,
    });

  const fetchThumbnail = (videoId: string) =>
    // (async () => {
    // const response = await
    api<StringResponse>({
      method: "GET",
      path: `/videos/${videoId}/thumbnail`,
      responseSchema: StringResponseSchema,
    });
  // .fetch();
  // dispatch({
  //   type: "ADD_THUMBNAIL_TO_VIDEO",
  //   payload: { response, videoId },
  // });
  // })();

  const fetchSubgroups = async (groupId: string) => {
    const response = await api<GetGroupSubgroupingsResponse>({
      method: "GET",
      path: `/groups/${groupId}/subgroupings`,
      responseSchema: GetGroupSubgroupingsResponseSchema,
      useSmartnEndpoint: true,
    }).fetch();
    if (response?.length) {
      dispatch({
        type: "ADD_GROUPS_TO_TREE",
        payload: { response, groupId },
      });
    }
    return response.map(({ subgroup }) => subgroup);
  };

  const startVideoUpload = (video: Resource) =>
    dispatch({
      type: "START_VIDEO_UPLOAD",
      payload: video,
    });

  const setUploadPercent = (videoId: string, newPercent: number) =>
    dispatch({
      type: "UPDATE_VIDEO_UPLOAD_PERCENT",
      payload: { videoId, newPercent },
    });

  const fetchTree = async () => {
    const queue: Group[] = [state.tree];
    while (queue.length) {
      // console.log("queue", queue);
      const group = queue.shift();
      if (group) {
        if (!group.videos?.length) {
          await fetchGroupShares(group.id);
        }
        if (group.childGroups && group.childGroups.length) {
          queue.push(...group.childGroups);
        } else {
          // Lazy load subgroups
          // fetchSubgroups(group.id);

          // Eager load subgroups
          const subgroups = await fetchSubgroups(group.id);
          queue.push(...subgroups);
        }
      }
    }
  };

  const findVideoByIdInTree = (videoId: string) =>
    Tree.findVideoById(state.tree, videoId);

  const findVideoByIdInTreeAndUploads = (videoId: string) =>
    findVideoByIdInTree(videoId) ||
    (state.uploads || []).find((v) => v.id === videoId);

  const fetchSharedWithForResourceId = throttle(
    async (resourceId: string) => {
      const response = await api<GetVideoSharesResponse>({
        method: "GET",
        path: `/videos/${resourceId}/shares`,
        responseSchema: GetVideoSharesResponseSchema,
        useSmartnEndpoint: true,
      }).fetch();
      // console.log("GetVideoSharesResponse:", response);
      if (response) {
        dispatch({
          type: "ADD_SHARED_WITH_TO_UPLOADS",
          payload: { response, videoId: resourceId },
        });
      }
    },
    2000,
    { leading: true, trailing: false }
  );

  const removeResources = (resourceIds: string[]) =>
    dispatch({ type: "REMOVE_RESOURCES", payload: resourceIds });

  return (
    <AppContext.Provider
      value={{
        ...state,
        dispatch,
        eventApi,
        logoutUser,
        loginUser,
        seekVideo,
        setSelectedResource,
        videoRef,
        openSnackbar,
        closeSnackbar,
        fetchUploads,
        fetchGroupShares,
        setSelectedGroup,
        setQuizScreen,
        addQuizResult,
        startVideoUpload,
        setUploadPercent,
        deleteVideo,
        getTranscript,
        shareVideo,
        createS3Url,
        fetchShareableGroups,
        getMedia,
        fetchThumbnail,
        fetchSubgroups,
        fetchTree,
        findVideoByIdInTree,
        findVideoByIdInTreeAndUploads,
        fetchSharedWithForResourceId,
        removeResources,
        resourceIndexApi,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider, reducerCreator };
