import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { en, CANCEL_BUTTON_LABEL } from "../i18n";
import { colors } from "../themes";

export type BaseModalProps = {
  children: React.ReactNode;
  heading: string;
  id: string;
  isModalOpen: boolean;
  close: () => void;
  onPrimaryButtonClick: () => void;
  primaryButtonLabel: string;
  primaryButtonDisabled?: boolean;
};

export default function BaseModal({
  children,
  heading,
  id,
  isModalOpen,
  close,
  onPrimaryButtonClick,
  primaryButtonLabel,
  primaryButtonDisabled,
}: BaseModalProps) {
  const handleClose = () => {
    if (close) {
      close();
    }
  };

  const handlePrimaryButtonClick = () => {
    if (onPrimaryButtonClick) {
      onPrimaryButtonClick();
    }
  };

  return (
    <Modal id={id} open={isModalOpen} onClose={handleClose}>
      <div
        style={{
          maxWidth: "90vw",
          minWidth: "50vw",
          position: "absolute",
          backgroundColor: colors.lightestGrey,
          padding: "16px 32px",
          top: `50%`,
          left: `50%`,
          transform: `translate(-50%, -50%)`,
          borderRadius: "16px",
        }}
      >
        <Typography variant="h5" align="center" sx={{ width: "100%", mb: 3 }}>
          {heading}
        </Typography>
        {children}
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 2 }}>
          <Button
            id={id + "-cancel-btn"}
            variant="outlined"
            onClick={handleClose}
          >
            {en[CANCEL_BUTTON_LABEL]}
          </Button>
          <Button
            id={id + "-primary-btn"}
            variant="contained"
            color="primary"
            onClick={handlePrimaryButtonClick}
            disabled={primaryButtonDisabled}
            sx={{ ml: 2 }}
          >
            {primaryButtonLabel}
          </Button>
        </Box>
      </div>
    </Modal>
  );
}
