import React, { useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Snackbar, ThemeProvider } from "@mui/material";
import Alert from "@mui/material/Alert";

// import OriginalHeader from "./OriginalHeader";
import Header from "./Header";
// import MyContent from "./MyContent";
import {
  getTheme,
  // getThemeLocalStorage,
  // setThemeLocalStorage,
  // themeName,
  // themeType,
} from "./themes";
import LoginRegister from "./LoginRegister";
// import ProtectedRoute from "./components/ProtectedRoute";
import { AppContext } from "./AppContext";
import reportWebVitals from "./reportWebVitals";
import {
  authBasePath,
  authLoginPath,
  myContentBasePath,
  uploadsPath,
  sendPerfEvents,
} from "./utils";
import { AuthState, OrbitsAppContext } from "./types";
import MyUploads from "./components/MyUploads";
import Subject from "./Subject";

const theme = getTheme();

function App() {
  const {
    authState,
    eventApi,
    isSnackbarOpen,
    snackbarSeverity,
    snackbarMessage,
    // openSnackbar,
    closeSnackbar,
  } = useContext<OrbitsAppContext>(AppContext);

  const isLoggedin = authState === AuthState.LOGGED_IN;

  useEffect(() => {
    if (isLoggedin) {
      // If you want to start measuring performance in your app, pass a function
      // to log results (for example: reportWebVitals(console.log))
      // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
      reportWebVitals((detail) =>
        sendPerfEvents(location.pathname, detail, eventApi)
      );
    }
  }, [authState]);

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={isSnackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={50000}
        onClose={closeSnackbar}
      >
        <Alert id="toast" onClose={closeSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {isLoggedin && <Header />}
      <Switch>
        <Route path={authBasePath}>
          {isLoggedin ? <Redirect to={myContentBasePath} /> : <LoginRegister />}
        </Route>
        {isLoggedin && (
          <>
            <Route path={uploadsPath} component={MyUploads} />
            <Route path={myContentBasePath} component={Subject} />
            <Redirect to={myContentBasePath} />
          </>
        )}
        <Route>
          {isLoggedin ? (
            <Redirect to={myContentBasePath} />
          ) : (
            <Redirect to={authLoginPath} />
          )}
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

export default App;
