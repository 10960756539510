import React, { useState } from "react";
import Box from "@mui/material/Box";

import {
  Button,
  Divider,
  Link,
  TextField,
  Typography,
} from "../styledComponents";
import ErrorBoundary from "./ErrorBoundary";
import {
  en,
  FORGOT_PASSWORD_QUESTION_MARK_TEXT,
  LOGIN_BUTTON_LABEL,
  LOGIN_SCREEN_REGESTRATION_NUDGE_TEXT,
  MISSING_PASSWORD_ERROR_MSG,
  NOT_REGISTERED_QUESTION_MARK_TEXT,
  NO_PROBLEM_EXCLAMATION_MARK_TEXT,
  PASSWORD_FIELD_LABEL,
  PASSWORD_SHOULD_BE_AT_LEAST_8_CHARS_ERROR_MSG,
  REGISTER_BUTTON_LABEL,
  USERNAME_FIELD_LABEL,
} from "../i18n";
import { validateUsername } from "../utils";
import { LoginRegisterClassesType } from "../types";
import { useStyles } from "../LoginRegister";

export type LoginPropsType = {
  login: (username: string, password: string) => Promise<void>;
  gotoForgetPassword: () => void;
  gotoRegister: () => void;
};

export default function Login(props: LoginPropsType) {
  const classes: LoginRegisterClassesType = useStyles();
  const { login, gotoForgetPassword, gotoRegister } = props;

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const validateAndSetUsername = (value: string) => {
    setUsernameError(validateUsername(value, true));
    setUsername(value);
  };
  const updateUsername = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    validateAndSetUsername(value);
  };

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const validateAndSetPassword = (value: string) => {
    setPasswordError("");
    if (!value) {
      setPasswordError(en[MISSING_PASSWORD_ERROR_MSG]);
    } else if (value.length < 8) {
      setPasswordError(en[PASSWORD_SHOULD_BE_AT_LEAST_8_CHARS_ERROR_MSG]);
    }
    setPassword(value);
  };
  const updatePassword = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    validateAndSetPassword(value);
  };

  const _login = () => {
    validateAndSetUsername(username);
    validateAndSetPassword(password);
    login(username, password);
  };

  return (
    <ErrorBoundary>
      <TextField
        id="username-field"
        autoFocus
        className={usernameError ? classes.errorField : classes.field}
        fullWidth
        label={en[USERNAME_FIELD_LABEL]}
        value={username}
        onBlur={updateUsername}
        onChange={updateUsername}
        error={Boolean(usernameError)}
        helperText={usernameError}
        autoComplete="username"
      />
      <TextField
        id="password-field"
        className={passwordError ? classes.errorField : classes.field}
        fullWidth
        label={en[PASSWORD_FIELD_LABEL]}
        type="password"
        value={password}
        onBlur={updatePassword}
        onChange={updatePassword}
        onKeyDown={(e) => e.key === "Enter" && _login()}
        error={Boolean(passwordError)}
        helperText={passwordError}
        autoComplete="current-password"
      />
      <Link className={classes.link} onClick={gotoForgetPassword}>
        {en[FORGOT_PASSWORD_QUESTION_MARK_TEXT]}
      </Link>
      <Button
        id="login-button"
        className={classes.btn}
        color="primary"
        fullWidth
        variant="contained"
        disabled={!!(!username || !password || usernameError || passwordError)}
        onClick={_login}
      >
        {en[LOGIN_BUTTON_LABEL]}
      </Button>
      <Divider />
      <Box sx={{ mt: 2 }}>
        <Typography color="primary" sx={{ display: "inline" }}>
          {en[NOT_REGISTERED_QUESTION_MARK_TEXT]}
        </Typography>
        <Typography color="secondary" sx={{ display: "inline" }}>
          {en[NO_PROBLEM_EXCLAMATION_MARK_TEXT]}
        </Typography>
      </Box>
      <Typography color="primary">
        {en[LOGIN_SCREEN_REGESTRATION_NUDGE_TEXT]}
      </Typography>
      <Button
        id="register-button"
        className={classes.btn}
        color="primary"
        fullWidth
        variant="outlined"
        onClick={gotoRegister}
      >
        {en[REGISTER_BUTTON_LABEL]}
      </Button>
    </ErrorBoundary>
  );
}
