import { QuestionType } from "../types";
import { shuffleArray } from "../utils";

export const questions: QuestionType[] = [
  {
    id: "1",
    topic: "Geography",
    question: "What is the capital of France?",
    answers: ["Paris", "London", "Berlin", "Madrid"],
    correctAnswer: "Paris",
    userAnswer: "",
  },
  {
    id: "2",
    topic: "Geography",
    question: "What is the capital of Germany?",
    answers: ["Paris", "London", "Berlin", "Madrid"],
    correctAnswer: "Berlin",
    userAnswer: "",
  },
  {
    id: "3",
    topic: "Geography",
    question: "What is the capital of Italy?",
    answers: ["Paris", "London", "Berlin", "Madrid", "Rome"],
    correctAnswer: "Rome",
    userAnswer: "",
  },
  {
    id: "4",
    topic: "Boolean",
    question: "is true true?",
    answers: ["true", "false"],
    correctAnswer: "true",
    userAnswer: "",
  },
  {
    id: "5",
    topic: "Boolean",
    question: "is false true?",
    answers: ["true", "false"],
    correctAnswer: "false",
    userAnswer: "",
  },
  {
    id: "6",
    topic: "Boolean",
    question: "is false false?",
    answers: ["true", "false"],
    correctAnswer: "true",
    userAnswer: "",
  },
  {
    id: "7",
    topic: "Boolean",
    question: "is true false?",
    answers: ["true", "false"],
    correctAnswer: "false",
    userAnswer: "",
  },
];

// shuffle the questions and answers
export const getShuffledQuestions = () =>
  shuffleArray(questions).map((q) => ({
    ...q,
    answers: shuffleArray(q.answers),
  }));

export const getTopics = () =>
  questions.reduce((acc, q) => {
    if (!acc.includes(q.topic)) {
      acc.push(q.topic);
    }
    return acc;
  }, [] as string[]);
