import { green, red } from "@mui/material/colors";
import { createTheme, adaptV4Theme } from "@mui/material/styles";

type themeName = "default" | "redGreen";

type themeType = "light" | "dark";

const fieldBackgroundColor = "#FFEDE2";
const fieldColor = "#C22C2C";
const errorFieldBackgroundColor = "#FFE2E2";
const errorFieldColor = "#C22C2C";

function getPalette(name: themeName) {
  switch (name) {
    case "redGreen":
      return {
        primary: {
          main: red[500],
        },
        secondary: {
          main: green[400],
        },
      };
    case "default":
    default:
      return {
        primary: {
          main: "#604BDF",
        },
        secondary: {
          main: "#d15318",
        },
      };
  }
}

function getTheme(name: themeName = "default") {
  return createTheme(
    adaptV4Theme({
      palette: {
        ...getPalette(name),
        // type,
        text: {
          disabled: getPalette(name).primary.main,
          // hint: getPalette(name).primary.main,
          primary: getPalette(name).primary.main,
          secondary: getPalette(name).primary.main,
        },
      },
      typography: {
        button: {
          textTransform: "none",
        },
      },
    })
  );
}

const colors = {
  white: "#FFF",
  black: "#000",
  grey: "#CCC",
  lightGrey: "#EEE",
  lightestGrey: "#F5F5F5",
  darkGrey: "#999",
  originalPrimary: "#044256",
  primary: "#604BDF",
  secondary: "#d15318",
};

const padding = {
  tiny: "2px",
  small: "8px",
  medium: "16px",
  large: "24px",
  xlarge: "32px",
};

export {
  getTheme,
  fieldBackgroundColor,
  fieldColor,
  errorFieldBackgroundColor,
  errorFieldColor,
  colors,
  padding,
};
export type { themeName, themeType };
