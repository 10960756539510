import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Hit } from "@aws-sdk/client-cloudsearch-domain";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import { colors } from "../themes";
import { OrbitsAppContext, ResourceType, resourceTypes } from "../types";
import VideoThumbnail from "./VideoThumbnail";
import { parseHighlights, myContentBasePath, uploadsPath } from "../utils";
import { AppContext } from "../AppContext";
import { Filter } from "./ContentSearchBar";

const MIN_QUERY_LENGTH = 3;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: alpha(theme.palette.common.black, 0.2),
  backgroundColor: alpha(theme.palette.common.white, 0.8),
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  pointerEvents: "none",
  left: theme.spacing(1),
  top: theme.spacing(1),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: 0,
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: "42px",
    [theme.breakpoints.up("lg")]: {
      width: "20ch",
    },
  },
}));

export default function GlobalSearch() {
  const history = useHistory();
  const {
    globalSearchResults,
    resourceIndexApi,
    setSelectedGroup,
    setSelectedResource,
    uploads,
  } = React.useContext<OrbitsAppContext>(AppContext);

  const [query, setQuery] = React.useState<string>("");
  const [active, setActive] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [type, setResourceType] = React.useState<ResourceType[]>([
    "audio",
    "video",
  ]);

  // const facets = globalSearchResults?.facets ?? undefined;
  const hits: Hit[] = globalSearchResults?.hits?.hit ?? [];

  React.useEffect(() => {
    // console.log(
    //   "GlobalSearch.tsx: useEffect",
    //   query,
    //   active,
    //   type,
    //   hits?.length
    // );
    if (query.length <= MIN_QUERY_LENGTH) {
      setLoading(false);
      return;
    }

    setLoading(true);
    resourceIndexApi(query, type);

    const loadingTimeout = setTimeout(() => setLoading(false), 2000);

    return () => {
      clearTimeout(loadingTimeout);
    };
  }, [query, type]);

  return (
    <Box
      onFocus={() => setActive(true)}
      // onBlur={() => setTimeout(() => setActive(false), 10_000)}
    >
      <Search sx={{ color: colors.black }}>
        <StyledInputBase
          inputProps={{
            placeholder: "Search all content",
            onChange: (e) =>
              setQuery((e.target as { value?: string }).value || ""),
          }}
        />
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
      </Search>
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          borderRadius: "8px",
          zIndex: 1,
          display: active ? "initial" : "none",
        }}
      >
        <CloseIcon
          sx={{
            float: "right",
            p: 1,
            marginBottom: "-30px",
            marginRight: "20px",
            display: "inline-block",
          }}
          onClick={() => setActive(false)}
        />
        <Paper
          sx={{
            border: `1px solid ${colors.primary}`,
            borderRadius: "4px",
            minHeight: "100px",
            zIndex: 1,
            minWidth: "10vw",
            height: "max-content",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              minWidth: 650,
              maxWidth: "50vw",
              overflowX: "auto",
              maxHeight: "50vh",
              overflowY: "scroll",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "black" }} align="center">
                    Preview
                  </TableCell>
                  <TableCell sx={{ color: "black" }} align="center">
                    Title
                  </TableCell>
                  <TableCell sx={{ color: "black" }} align="center">
                    Transcript
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hits.length > 0 && query.length > MIN_QUERY_LENGTH ? (
                  hits.map(({ highlights, fields, id }) => (
                    <TableRow
                      key={id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      onClick={() => {
                        if (fields?.group_ids?.length && fields?.group_ids[0]) {
                          history.push(myContentBasePath);
                          setSelectedGroup({
                            id: fields?.group_ids[0],
                            displayName: "",
                          });
                          setTimeout(() => setSelectedResource(id + ""), 0);
                        } else if (uploads?.find((u) => u.id === id)) {
                          history.push(uploadsPath);
                        } else {
                          console.log("no group id");
                        }
                        setActive(false);
                      }}
                      data-testid="global-search-result-row"
                    >
                      <TableCell component="th" scope="row">
                        <VideoThumbnail
                          videoId={id || ""}
                          type={
                            (fields?.resource_type[0] as ResourceType) ||
                            "video"
                          }
                          // type="video"
                          extendSx={{ flexGrow: 1 }}
                          disableOnClick
                        />
                      </TableCell>
                      <TableCell sx={{ color: "black" }} align="left">
                        {parseHighlights(highlights?.title, query).map(
                          ({ str, highlighted }, idx) => (
                            <span
                              key={idx}
                              style={{
                                fontWeight: highlighted ? "bold" : "normal",
                              }}
                            >
                              {str}
                            </span>
                          )
                        )}
                      </TableCell>
                      <TableCell sx={{ color: "black" }} align="left">
                        {parseHighlights(highlights?.body, query).map(
                          ({ str, highlighted }, idx) => (
                            <span
                              key={idx}
                              style={{
                                fontWeight: highlighted ? "bold" : "normal",
                              }}
                            >
                              {str}
                            </span>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                    {query.length >= MIN_QUERY_LENGTH ? (
                      <TableCell>
                        {loading ? "Loading..." : "No results found."}
                      </TableCell>
                    ) : (
                      <TableCell>
                        Please type at least 3 characters to begin search.
                      </TableCell>
                    )}
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: "flex" }}>
            <Filter
              key="resource-type"
              eleKey="resource-type"
              label="Resource Type"
              options={resourceTypes}
              setSelection={(newSelection) =>
                setResourceType(newSelection as ResourceType[])
              }
            />
          </Box>
          {/* <Box sx={{ display: "flex" }}>
              {facets &&
                Object.entries(facets)
                  .filter(
                    ([, value]) =>
                      value && value.buckets && value.buckets.length > 1
                  )
                  .map(([key, value]) => (
                    <Filter
                      key={key}
                      eleKey={key}
                      label={key.split("_").map(capitalize).join(" ")}
                      options={
                        value.buckets?.map(({ value }) => value || "") || []
                      }
                    />
                  ))}
            </Box> */}
        </Paper>
      </Box>
    </Box>
  );
}
