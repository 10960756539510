import { Typography, Alert, Grid, TextField } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import * as React from "react";

import {
  DEFAULT_ERROR_MSG,
  EMAIL_FIELD_LABEL,
  en,
  FIRST_NAME_FIELD_LABEL,
  INVITE_A_FRIEND_MODAL_GUIDE_TEXT,
  INVITE_A_FRIEND_MODAL_TITLE,
  // INVITE_A_FRIEND_SUCCESS_MSG,
  LAST_NAME_FIELD_LABEL,
  MISSING_EMAIL_ERROR_MSG,
  MISSING_FIRST_NAME_ERROR_MSG,
  MISSING_LAST_NAME_ERROR_MSG,
  SEND_BUTTON_EMAIL,
} from "../i18n";
import {
  fieldColor,
  fieldBackgroundColor,
  errorFieldColor,
  errorFieldBackgroundColor,
} from "../themes";
import { OrbitsAppContext } from "../types";
import { validateEmail as validateEmailUtil } from "../utils";
import BaseModal from "./BaseModal";

const useStyles = makeStyles(() =>
  createStyles({
    field: {
      color: fieldColor,
      backgroundColor: fieldBackgroundColor,
    },
    errorField: {
      "& .Mui-error": {
        color: errorFieldColor,
      },
      "& .MuiFormHelperText-root": {
        color: errorFieldColor,
      },
      backgroundColor: errorFieldBackgroundColor,
    },
  })
);

export type InviteFriendModalProps = {
  open: boolean;
  close: () => void;
  eventApi: OrbitsAppContext["eventApi"];
};

export default function InviteFriendModal({
  open,
  close,
  eventApi,
}: InviteFriendModalProps) {
  const classes = useStyles();

  const [inviteFriendError, setInviteFriendError] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState("");
  const validateAndSetFirstName = (value: string) => {
    setFirstNameError("");
    if (!value) {
      setFirstNameError(en[MISSING_FIRST_NAME_ERROR_MSG]);
    }
    setFirstName(value);
  };
  const updateFirstName = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    validateAndSetFirstName(value);
  };

  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const validateAndSetLastName = (value: string) => {
    setLastNameError("");
    if (!value) {
      setLastNameError(en[MISSING_LAST_NAME_ERROR_MSG]);
    }
    setLastName(value);
  };
  const updateLastName = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    validateAndSetLastName(value);
  };

  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const validateAndSetEmail = (value: string) => {
    setEmailError("");
    if (!value) {
      setEmailError(en[MISSING_EMAIL_ERROR_MSG]);
    } else {
      const validationError = validateEmailUtil(value);
      if (validationError) {
        setEmailError(validationError);
      }
    }
    setEmail(value);
  };
  const updateEmail = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    validateAndSetEmail(value);
  };

  const clearInviteFriendData = () => {
    setFirstName("");
    setFirstNameError("");
    setLastName("");
    setLastNameError("");
    setEmail("");
    setEmailError("");
    setInviteFriendError("");
  };

  const sendInvite = async () => {
    try {
      setInviteFriendError("");
      await eventApi(
        {
          detailType: "ORBITS Friend Invited",
          detail: { recipient: { firstName, lastName, email } },
        },
        true
      );
      // openSnackbar("success", en[INVITE_A_FRIEND_SUCCESS_MSG](email));
      // setShowInviteFriendModal(false);
      clearInviteFriendData();
    } catch (e) {
      setInviteFriendError(en[DEFAULT_ERROR_MSG]);
    }
  };

  return (
    <BaseModal
      heading={en[INVITE_A_FRIEND_MODAL_TITLE]}
      id="invite-friend-modal"
      isModalOpen={open}
      close={close}
      onPrimaryButtonClick={sendInvite}
      primaryButtonLabel={en[SEND_BUTTON_EMAIL]}
      primaryButtonDisabled={
        !!(
          firstNameError ||
          lastNameError ||
          emailError ||
          !firstName ||
          !lastName ||
          !email
        )
      }
    >
      <>
        <div data-testid="invite-friend-modal-heading">
          <Typography
            variant="body1"
            align="left"
            color="primary"
            sx={{ mt: 2 }}
          >
            {en[INVITE_A_FRIEND_MODAL_GUIDE_TEXT]}
          </Typography>
        </div>
        {inviteFriendError && (
          <Alert severity="error">{inviteFriendError}</Alert>
        )}
        <Grid container pt={2}>
          <Grid item xs={6} pr={2}>
            <TextField
              autoFocus
              className={firstNameError ? classes.errorField : classes.field}
              fullWidth
              label={en[FIRST_NAME_FIELD_LABEL]}
              value={firstName}
              onChange={updateFirstName}
              error={Boolean(firstNameError)}
              helperText={firstNameError}
            />
          </Grid>
          <Grid item xs={6} pl={2}>
            <TextField
              className={lastNameError ? classes.errorField : classes.field}
              fullWidth
              label={en[LAST_NAME_FIELD_LABEL]}
              value={lastName}
              onChange={updateLastName}
              error={Boolean(lastNameError)}
              helperText={lastNameError}
            />
          </Grid>
          <TextField
            className={emailError ? classes.errorField : classes.field}
            fullWidth
            label={en[EMAIL_FIELD_LABEL]}
            value={email}
            onChange={updateEmail}
            error={Boolean(emailError)}
            helperText={emailError}
            sx={{ mt: 2 }}
          />
        </Grid>
      </>
    </BaseModal>
  );
}
