import * as React from "react";
import {
  DataGrid,
  GridColumns,
  GridSelectionModel,
  GridRowClassNameParams,
} from "@mui/x-data-grid";
import { createStyles, makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";

import { colors } from "../themes";
import { Resource, OrbitsAppContext } from "../types";
import { AppContext } from "../AppContext";

const useStyles = makeStyles(() =>
  createStyles({
    dataGridColumnLabel: {
      fontSize: "18px",
      color: colors.black,
      fontWeight: "bold",
    },
    selectedRow: {
      backgroundColor: alpha(colors.primary, 0.1),
      border: `4px solid ${alpha(colors.primary, 0.8)}`,
    },
    row: {
      border: "4px solid white",
    },
  })
);

export type DataGridPropType = {
  contentHightAbove: number;
  rows: Resource[];
  columns: GridColumns<Resource>;
  disableSelectionOnClick?: boolean;
  checkboxSelection?: boolean;
  disableColumnMenu?: boolean;
  disableSelectedRowBorder?: boolean;
  onSelectionModelChange?: (selection: GridSelectionModel) => void;
  selectedResourceIds?: GridSelectionModel;
};

export default function ({
  contentHightAbove,
  rows,
  columns,
  disableSelectionOnClick,
  checkboxSelection,
  disableColumnMenu,
  disableSelectedRowBorder,
  onSelectionModelChange,
  selectedResourceIds,
}: DataGridPropType) {
  const classes = useStyles();
  const { selectedResourceId } = React.useContext<OrbitsAppContext>(AppContext);
  // const [selectionModel, setSelectionModel] = React.useState<undefined | GridSelectionModel>(preselectedResourceIds);
  // React.useEffect(() => setSelectionModel([selectedResourceId]), [selectedResourceId]);

  return (
    <DataGrid
      sx={{
        height: `calc(100vh - ${contentHightAbove}px)`,
        minHeight: "300px",
        width: "100%",
        mt: 1,
      }}
      rows={rows}
      // All common properties for column definition
      columns={columns.map((col) => ({
        ...col,
        headerClassName: classes.dataGridColumnLabel,
      }))}
      checkboxSelection={checkboxSelection}
      disableColumnMenu={disableColumnMenu}
      selectionModel={selectedResourceIds}
      onSelectionModelChange={onSelectionModelChange}
      rowHeight={64}
      rowCount={rows.length}
      getRowClassName={(params: GridRowClassNameParams) =>
        params.row.id === selectedResourceId && !disableSelectedRowBorder
          ? classes.selectedRow
          : classes.row
      }
      disableSelectionOnClick={
        disableSelectionOnClick === undefined ? false : disableSelectionOnClick
      }
    />
  );
}
