import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  TextField,
  Typography,
  InputLabel,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  MenuItem,
  Divider,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import UploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";

import { AppContext } from "../AppContext";
import { Group, Tree, OrbitsAppContext } from "../types";
import { errorFieldBackgroundColor, errorFieldColor, colors } from "../themes";
import ErrorBoundary from "./ErrorBoundary";
import {
  EMPTY_SELECTED_SHARE_WITH_GRUOPS_LABEL,
  en,
  FAILED_TO_CAPTURE_FILE_ERROR_MSG,
  FILE_NAME_FIELD_LABEL,
  FILE_SIZE_OVER_LIMIT_ERROR_MSG,
  INVALID_FILE_ERROR_MSG,
  INVALID_FILE_EXTENSION_ERROR_MSG,
  INVALID_FILE_NAME_ERROR_MSG,
  MISSING_NAME_ERROR_MSG,
  NAME_FIELD_LABEL,
  NAME_SHOULD_BE_AT_LEAST_3_CHARS_ERROR_MSG,
  PAGINATION_OF_TEXT,
  PAGINATION_PAGE_TEXT,
  SHARE_YOUR_RESOURCE_SUBHEADING,
  UPLOAD_FILE_FIELD_LABEL,
} from "../i18n";
import BaseModal from "./BaseModal";
import Box from "@mui/system/Box";

const useStyles = makeStyles(() =>
  createStyles({
    field: {
      color: colors.black,
      backgroundColor: colors.white,
    },
    errorField: {
      "& .Mui-error": {
        color: errorFieldColor,
      },
      "& .MuiFormHelperText-root": {
        color: errorFieldColor,
      },
      color: errorFieldColor,
      backgroundColor: errorFieldBackgroundColor,
    },
    visuallyHidden: {
      position: "absolute",
      height: "1px",
      width: "1px",
      overflow: "hidden",
      clip: "rect(1px, 1px, 1px, 1px)",
    },
    fileError: {
      backgroundColor: errorFieldBackgroundColor,
    },
    fileErrorText: {
      "& .Mui-error": {
        color: errorFieldColor,
      },
      "& .MuiFormHelperText-root": {
        color: errorFieldColor,
      },
      color: errorFieldColor,
    },
    groupsSelectorSearch: {
      display: "block",
      backgroundColor: colors.white,
      borderRadius: "8px 8px 0px 0px",
    },
    tableHeading: {
      fontSize: "16px",
      paddingTop: "0",
      display: "inline-block",
      marginRight: "24px",
    },
  })
);

const validExtensions = [
  // MP4 -> .mp4
  "mp4",
  // OGG -> .ogg, .ogv, .ogx
  "ogg",
  "ogv",
  "ogx",
  // WEBM -> .webm
  "webm",
  // MOV -> .mov, .qt
  "mov",
  "qt",
  // PDF -> .pdf
  "pdf",
];

const uploadLimitInMB = 2000; // 2 GB

const pageSize = 5;

const groupsSelectorField = "groups-selector-field";

function UploadVideoButton({
  hide,
  preSelectedGroup,
}: {
  hide?: boolean;
  preSelectedGroup?: Group;
}) {
  const classes = useStyles();
  const {
    startVideoUpload,
    cognitioUser,
    shareVideo,
    createS3Url,
    sharableGroups,
    setUploadPercent,
    uploads,
    tree,
    fetchShareableGroups,
  } = useContext<OrbitsAppContext>(AppContext);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileError, setFileError] = useState<string>("");
  const [isBtnClicked, setIsBtnClicked] = useState(false);
  const [shareWithSearch, setShareWithSearch] = useState("");
  const [dropdownGroups, setDropdownGroups] = useState<Group[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [currentpageSelectedGroups, setCurrentpageSelectedGroups] = useState<
    Group[]
  >([]);
  const [
    selectedGroupsPageNumber,
    setSelectedGroupsPageNumber,
  ] = useState<number>(1);
  const [
    selectedGroupsTotalPages,
    setSelectedGroupsTotalPages,
  ] = useState<number>(1);
  const [
    isgroupsSelectorSearchFocused,
    setIsgroupsSelectorSearchFocused,
  ] = useState<boolean>(false);

  const validateName = (name: string) => {
    setNameError("");
    if (!name) {
      setNameError(en[MISSING_NAME_ERROR_MSG]);
    }
    if (name.length < 3) {
      setNameError(en[NAME_SHOULD_BE_AT_LEAST_3_CHARS_ERROR_MSG]);
    }
  };

  const _setName = (e?: { target?: { value?: string } }) => {
    const name = e?.target?.value || "";
    setName(name);
    if (nameError) {
      validateName(name);
    }
  };

  const handleOpen = () => {
    // if (uploadStatus === UploadStatusType.UPLOADING) {
    if (
      uploads &&
      uploads.some(
        (upload) =>
          upload.uploadPercent !== undefined &&
          0 < upload.uploadPercent &&
          upload.uploadPercent < 100
      )
    ) {
      console.log("Can't open modal when video is uplaoding");
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setName("");
    setNameError("");
    setFile(undefined);
    setFileError("");
    setIsBtnClicked(false);
    setSelectedGroups([]);
  };

  const preventDefaultAndPropagation = (
    e:
      | React.DragEvent<HTMLDivElement>
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (
    e:
      | React.DragEvent<HTMLDivElement>
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    console.log("handleDrag Drop", e);
    preventDefaultAndPropagation(e);
    setFileError("");

    let inputFile: File | undefined;

    if ("dataTransfer" in e) {
      inputFile = e.dataTransfer.files[0];
      e.dataTransfer.clearData();
    } else if ("files" in e.target && e.target.files) {
      inputFile = e.target.files[0];
    } else {
      setFileError(en[FAILED_TO_CAPTURE_FILE_ERROR_MSG]);
      return;
    }

    if (!inputFile?.name) {
      setFileError(en[INVALID_FILE_NAME_ERROR_MSG]);
      return;
    }

    const extensionArr = inputFile.name.split(".");
    const extension = extensionArr[extensionArr.length - 1].toLowerCase();
    const isValidExtensions = validExtensions.some(
      (validExtension) => extension === validExtension
    );

    if (!isValidExtensions) {
      const fileExtension = extension.toUpperCase();
      const validFileExtensions = validExtensions
        .map((str) => str.toUpperCase())
        .join(", ");

      setFileError(
        en[INVALID_FILE_EXTENSION_ERROR_MSG](fileExtension, validFileExtensions)
      );
      return;
    }

    if (typeof inputFile.size !== "number") {
      setFileError(en[INVALID_FILE_ERROR_MSG]);
      return;
    }

    const fileSizeInMB = inputFile.size / Math.pow(10, 6);

    const overLimitInGB =
      (Math.round(fileSizeInMB) - uploadLimitInMB) / Math.pow(10, 3);

    console.log({ fileSizeInMB, overLimitInGB });

    if (fileSizeInMB >= uploadLimitInMB) {
      setFileError(en[FILE_SIZE_OVER_LIMIT_ERROR_MSG](overLimitInGB));
      return;
    }

    setFile(inputFile);
  };

  const uploadBtnClicked = async () => {
    if (isBtnClicked) return;
    // disable the upload button by resetting the value for isBtnClicked
    setIsBtnClicked(true);

    if (!file) {
      setFileError(en[FAILED_TO_CAPTURE_FILE_ERROR_MSG]);
      setIsBtnClicked(false);
      return;
    }

    try {
      const { fields, url } = await createS3Url(name);
      const videoId = fields["key"];
      if (!videoId) {
        throw new Error("Failed to create signed url");
      }

      const formData = new FormData();
      Object.entries(fields).forEach(([field, value]) =>
        formData.append(field, value + "")
      );
      formData.append("Content-Type", file?.type);
      formData.append("file", file);
      setOpen(false);
      startVideoUpload({
        id: videoId,
        title: name,
        ctime: new Date().toISOString(),
        creator: cognitioUser?.username || "",
        sharedWith: preSelectedGroup ? [preSelectedGroup] : selectedGroups,
        uploadPercent: 1,
        type: "video",
      });

      const onUploadProgress = (progressEvent: {
        loaded: number;
        total: number;
      }) =>
        setUploadPercent(
          videoId,
          (progressEvent.loaded * 100) / progressEvent.total
        );

      const resp = await axios.post(url, formData, { onUploadProgress });
      console.log("uploadBtnClicked: ", resp);

      // wait for five seconds for the video data to get into DynamoDB
      // TODO: implement a better solution
      setTimeout(async () => {
        const sharePromises = (preSelectedGroup
          ? [preSelectedGroup]
          : selectedGroups
        ).map(({ id }) => shareVideo(videoId, id));
        const resp = await Promise.all(sharePromises);
        console.log("upload finished", videoId, resp);
      }, 5_000);
    } catch (e) {
      console.error(e);
    }
  };

  const _setShareWithSearch = (event: React.ChangeEvent<{ value: string }>) => {
    setShareWithSearch(event.target.value);
  };

  const addToSelectedGroups = (sg: Group) => {
    setShareWithSearch("");

    if (selectedGroups.some(({ id }) => id === sg.id)) {
      return;
    }

    setSelectedGroups([...selectedGroups, sg]);
  };

  const removefromSelectedGroups = (id: string) =>
    setSelectedGroups(
      selectedGroups.filter((sg: { id: string }) => sg.id !== id)
    );

  const _setSelectedGroupsPageNumber = (
    newSelectedGroupsPageNumber: number
  ) => {
    if (newSelectedGroupsPageNumber < 1) {
      newSelectedGroupsPageNumber = 1;
    } else if (newSelectedGroupsPageNumber > selectedGroupsTotalPages) {
      newSelectedGroupsPageNumber = selectedGroupsTotalPages;
    }
    setSelectedGroupsPageNumber(newSelectedGroupsPageNumber);
  };

  useEffect(() => {
    const newSelectedGroupsTotalPages = Math.max(
      Math.ceil(selectedGroups.length / pageSize),
      1
    );

    setSelectedGroupsTotalPages(newSelectedGroupsTotalPages);
  }, [selectedGroups]);

  useEffect(() => {
    const newCurrentpageSelectedGroups = selectedGroups.filter(
      (_, idx: number) =>
        idx >= (selectedGroupsPageNumber - 1) * pageSize &&
        idx < selectedGroupsPageNumber * pageSize
    );
    setCurrentpageSelectedGroups(newCurrentpageSelectedGroups);
  }, [selectedGroups, selectedGroupsPageNumber]);

  useEffect(() => {
    if (document.activeElement?.id === groupsSelectorField && sharableGroups) {
      const searchRe = new RegExp(shareWithSearch, "i");
      setDropdownGroups(
        shareWithSearch
          ? sharableGroups.filter(({ displayName }) =>
              searchRe.test(displayName)
            )
          : sharableGroups
      );
    } else {
      setDropdownGroups([]);
    }
  }, [isgroupsSelectorSearchFocused, shareWithSearch]);

  useEffect(() => {
    if (selectedGroupsPageNumber > selectedGroupsTotalPages) {
      setSelectedGroupsPageNumber(selectedGroupsTotalPages);
    }
  }, [selectedGroupsPageNumber, selectedGroupsTotalPages]);

  useEffect(() => {
    if (fileError) {
      setFile(undefined);
    }
  }, [fileError]);

  useEffect(() => {
    if (!open) {
      setName("");
      setNameError("");
      setFile(undefined);
      setFileError("");

      // enable the upload button by resetting the value for isBtnClicked when modal is closed
      setIsBtnClicked(false);
    }
  }, [open]);

  if (!sharableGroups) {
    fetchShareableGroups();
  }

  const getGroupPath = (group: Group) =>
    Tree.findGroupPathById(tree, group.id)
      .map(({ displayName }) => displayName)
      .join(" > ");

  const pagination =
    selectedGroupsTotalPages > 1 ? (
      <TableRow className="pagination">
        <TableCell>
          <Typography>
            {en[PAGINATION_PAGE_TEXT]} {selectedGroupsPageNumber}{" "}
            {en[PAGINATION_OF_TEXT]} {selectedGroupsTotalPages}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <ArrowBackIosIcon
            onClick={() =>
              _setSelectedGroupsPageNumber(selectedGroupsPageNumber - 1)
            }
          />
          <ArrowForwardIosIcon
            onClick={() =>
              _setSelectedGroupsPageNumber(selectedGroupsPageNumber + 1)
            }
          />
        </TableCell>
      </TableRow>
    ) : undefined;

  return (
    <ErrorBoundary>
      {!hide && (
        <Button
          id="upload-button"
          // sx={{ display:  ? "none" : "default" }}
          onClick={handleOpen}
          color="primary"
          variant="contained"
          startIcon={<UploadIcon />}
        >
          Upload Content
        </Button>
      )}
      <BaseModal
        heading="Upload New Content"
        id="upload-video-modal"
        isModalOpen={open}
        close={handleClose}
        onPrimaryButtonClick={uploadBtnClicked}
        primaryButtonDisabled={
          !!(fileError || nameError || !file || isBtnClicked)
        }
        primaryButtonLabel="Upload"
      >
        <InputLabel
          className={fileError ? classes.fileErrorText : ""}
          style={{ color: colors.black }}
        >
          {en[UPLOAD_FILE_FIELD_LABEL]}
        </InputLabel>
        <div
          id="file-input"
          className={
            fileError ? classes.fileError + " " + classes.fileErrorText : ""
          }
          onDrop={handleDrop}
          onDrag={preventDefaultAndPropagation}
          onDragEnter={preventDefaultAndPropagation}
          onDragOver={preventDefaultAndPropagation}
          onDragExit={preventDefaultAndPropagation}
          style={{
            marginTop: "8px",
            width: "100%",
            height: "128px",
            backgroundColor: colors.white,
            borderRadius: "8px",
            borderStyle: "dashed",
            borderColor: colors.grey,
            borderWidth: "2px",
            color: colors.black,
          }}
        >
          <Input
            id="fileInput"
            className={classes.visuallyHidden}
            type="file"
            value=""
            onChange={handleDrop}
          />
          <InputLabel
            className={fileError ? classes.fileErrorText : " "}
            htmlFor="fileInput"
            style={{ color: colors.darkGrey }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-evenly",
              height: "100%",
              pb: 4,
              mb: 2,
            }}
          >
            <UploadIcon
              sx={{ height: "64px", width: "64px", color: colors.darkGrey }}
            />
            <Typography>
              {file?.name ? file.name : en[FILE_NAME_FIELD_LABEL]}
            </Typography>
          </InputLabel>
        </div>
        <Typography className={fileError ? classes.fileErrorText : ""}>
          {fileError}
        </Typography>
        <TextField
          autoFocus
          className={nameError ? classes.errorField : classes.field}
          fullWidth
          label={en[NAME_FIELD_LABEL]}
          value={name}
          onChange={_setName}
          onBlur={() => validateName(name)}
          error={Boolean(nameError)}
          helperText={nameError}
          sx={{ mt: 4, input: { color: "black" }, label: { color: "black" } }}
        />
        {!preSelectedGroup &&
          sharableGroups !== undefined &&
          sharableGroups.length !== 0 && (
            <>
              <Typography
                id="groups-selector-label"
                variant="h6"
                sx={{ mt: 2, color: colors.black }}
              >
                {en[SHARE_YOUR_RESOURCE_SUBHEADING]}
              </Typography>
              <TextField
                className={classes.groupsSelectorSearch}
                placeholder=""
                id={groupsSelectorField}
                fullWidth
                label="Choose Subject"
                value={shareWithSearch}
                onChange={_setShareWithSearch}
                onBlur={() =>
                  setTimeout(() => setIsgroupsSelectorSearchFocused(false), 500)
                }
                onFocus={() => setIsgroupsSelectorSearchFocused(true)}
                sx={{
                  mt: 1,
                  input: { color: "black" },
                  label: { color: "black" },
                }}
              ></TextField>
              <Box
                sx={{
                  backgroundColor: colors.lightGrey,
                  position: "absolute",
                  width: "contain",
                  zIndex: 1,
                  ml: 0,
                  px: 2,
                  borderRadius: "0 0 8px 8px",
                  maxHeight: "25vh",
                  overflowY: "scroll",
                  overflowX: "scroll",
                }}
              >
                {dropdownGroups?.map((sg) => (
                  <MenuItem
                    className="groups-selector-option"
                    key={sg.id}
                    value={sg.id}
                    onClick={() => {
                      setIsgroupsSelectorSearchFocused(false);
                      addToSelectedGroups(sg);
                    }}
                    sx={{ mt: 1, color: colors.black, p: 1 }}
                  >
                    {getGroupPath(sg)}
                    <Divider sx={{ my: 1 }} />
                  </MenuItem>
                ))}
              </Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {pagination}
                    {currentpageSelectedGroups?.length !== 0 ? (
                      currentpageSelectedGroups.map(
                        ({ displayName, id }: Group) => (
                          <TableRow
                            key={id}
                            className="selected-sharable-group"
                          >
                            <TableCell sx={{ color: colors.black }}>
                              {displayName}
                            </TableCell>
                            <TableCell align="right">
                              <RemoveCircleIcon
                                onClick={() => removefromSelectedGroups(id)}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      )
                    ) : (
                      <TableRow id="selected-share-with-groups-placeholder">
                        <TableCell>
                          <Typography sx={{ color: colors.black }}>
                            {en[EMPTY_SELECTED_SHARE_WITH_GRUOPS_LABEL]}
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                    {pagination}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
      </BaseModal>
    </ErrorBoundary>
  );
}

export default UploadVideoButton;
