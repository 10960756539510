import React, { useState, useContext, useEffect } from "react";

import { AppContext } from "../AppContext";
import VideoMenu from "./VideoMenu";
import {
  OrbitsAppContext,
  OrbitsVideoEventDetailType,
  ResourceType,
  videoEventsType,
} from "../types";

const videoEvents: videoEventsType[] = ["pause", "playing", "seeked"];

const videoEventsMap: Record<videoEventsType, OrbitsVideoEventDetailType> = {
  pause: "ORBITS Video Paused",
  playing: "ORBITS Video Played",
  seeked: "ORBITS Video Seeked",
};

export default function ({
  videoId,
  type,
}: {
  videoId: string;
  type: ResourceType;
}) {
  const {
    videoRef,
    selectedGroupId,
    eventApi,
    getMedia,
  } = useContext<OrbitsAppContext>(AppContext);
  const [videoSrc, setVideoSrc] = useState("");
  const [selectedPlaybackRate, setSelectedPlaybackRate] = useState<number>(1.0);

  const updatePlaybackRate = (rate: number) => {
    if (videoRef?.current) {
      videoRef.current.playbackRate = rate;
      setSelectedPlaybackRate(videoRef.current.playbackRate);
    }
  };

  useEffect(() => {
    const eventListeners: Record<videoEventsType, () => void> = {
      pause: () => {},
      playing: () => {},
      seeked: () => {},
    };

    const videoEventListener = (detailType: OrbitsVideoEventDetailType) => {
      const detail = {
        startTime: videoRef?.current?.currentTime,
        videoId: videoId,
        groupId: selectedGroupId,
      };
      eventApi({ detailType, detail });
    };

    if (videoRef?.current && videoId) {
      videoEvents.map((event: videoEventsType) => {
        eventListeners[event] = () => videoEventListener(videoEventsMap[event]);
        videoRef?.current?.addEventListener(event, eventListeners[event]);
      });
    }

    return () => {
      if (videoRef?.current) {
        videoEvents.map((event: videoEventsType) => {
          videoRef?.current?.removeEventListener(event, eventListeners[event]);
        });
      }
    };
  }, [videoRef, videoId]);

  useEffect(() => {
    videoSrc && updatePlaybackRate(selectedPlaybackRate);
  }, [videoSrc]);

  useEffect(() => {
    if (videoId) {
      const request = getMedia(videoId);
      (async () => {
        const redirectedUrl = await request.fetch();
        if (!redirectedUrl) {
          return;
        }
        setVideoSrc(redirectedUrl);
      })();
      return () => request.cancel();
    }
    return () => {};
  }, [videoId]);

  return (
    <>
      {type === "video" && (
        <video
          ref={videoRef}
          src={videoSrc}
          controls
          controlsList="nodownload"
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "8px",
            borderBottomRightRadius: "0px 0px",
          }}
        />
      )}
      {type === "audio" && (
        <audio
          ref={videoRef}
          src={videoSrc}
          controls
          controlsList="nodownload"
          style={{
            width: "100%",
            height: "42px",
            borderRadius: "8px",
            borderBottomRightRadius: "0px 0px",
          }}
        />
      )}
      <VideoMenu
        selectedPlaybackRate={selectedPlaybackRate}
        updatePlaybackRate={updatePlaybackRate}
      />
    </>
  );
}
