import { Box, SxProps, Theme, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";

import { colors } from "../themes";
import { OrbitsAppContext, ResourceType } from "../types";
import ErrorBoundary from "./ErrorBoundary";
import multimediaIcon from "../images/multimedia-icon.svg";
import pdfIcon from "../images/pdf-icon.svg";

const loadingText = "Loading...";

function VideoThumbnail({
  videoId,
  type,
  extendSx,
  disableOnClick,
}: {
  videoId: string;
  type: ResourceType;
  extendSx?: SxProps<Theme>;
  disableOnClick?: boolean;
}) {
  const {
    fetchThumbnail,
    findVideoByIdInTreeAndUploads,
    setSelectedResource,
  } = useContext<OrbitsAppContext>(AppContext);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>("");
  const [processingStateLabel, setProcessingStateLabel] = useState(loadingText);

  useEffect(() => {
    const timer = setTimeout(() => setProcessingStateLabel(""), 5000);
    return () => clearTimeout(timer);
  }, [videoId]);

  const video = videoId && findVideoByIdInTreeAndUploads(videoId);
  const uploadPercent = video ? video.uploadPercent : undefined;

  useEffect(() => {
    if (!videoId) return;
    if (!thumbnailUrl) {
      const request = fetchThumbnail(videoId);
      (async () => setThumbnailUrl(await request.fetch()))();

      return () => request.cancel();
    }
    return;
  }, [videoId]);

  return (
    <ErrorBoundary>
      <Box
        onClick={() =>
          videoId && !disableOnClick && setSelectedResource(videoId)
        }
        sx={{
          height: "42px",
          width: "auto",
          minWidth: "64px",
          borderRadius: "4px",
          ...extendSx,
        }}
      >
        {type == "video" && (
          <>
            {thumbnailUrl ? (
              <img
                src={thumbnailUrl}
                style={{
                  height: "inherit",
                  width: "inherit",
                  borderRadius: "inherit",
                }}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignContent: "space-evenly",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  backgroundColor: colors.lightGrey,
                  borderRadius: "inherit",
                  pl: 1,
                }}
              >
                {uploadPercent === undefined && (
                  <Typography
                    color="secondary"
                    data-testid="undefined-upload-percent"
                  >
                    {processingStateLabel}
                  </Typography>
                )}
                {uploadPercent === 100 && (
                  <Typography
                    color="secondary"
                    data-testid="100-upload-percent"
                  >
                    Processing
                  </Typography>
                )}
                {uploadPercent !== undefined && uploadPercent !== 100 && (
                  <Typography
                    color="secondary"
                    data-testid="non-100-upload-percent"
                  >
                    {`Uploading: ${uploadPercent}%`}
                  </Typography>
                )}
              </Box>
            )}
          </>
        )}
        {type === "audio" && (
          <img
            src={multimediaIcon}
            style={{
              height: "inherit",
              width: "inherit",
              borderRadius: "inherit",
            }}
          />
        )}
        {type === "pdf" && (
          <img
            src={pdfIcon}
            style={{
              height: "inherit",
              width: "inherit",
              borderRadius: "inherit",
            }}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
}

export default VideoThumbnail;
