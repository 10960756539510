// ErrorBoundary
export const ERROR_BOUNDARY_MSG = "ERROR_BOUNDARY_MSG";

// ForgotPassword & ForgotPasswordSubmit
export const RECOVER_YOUR_PASSWORD_HEADING = "RECOVER_YOUR_PASSWORD_HEADING";
export const CHANGE_YOUR_PASSWORD_HEADING = "CHANGE_YOUR_PASSWORD_HEADING";
export const FORGOT_PASSWORD_GUIDE_TEXT = "FORGOT_PASSWORD_GUIDE_TEXT";
export const FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT =
  "FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT";
export const FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT_2 =
  "FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT_2";
export const USERNAME_OR_EMAIL_FIELD_LABEL = "USERNAME_OR_EMAIL_FIELD_LABEL";
export const CONFIRMATION_CODE_FILED_LABEL = "CONFIRMATION_CODE_FILED_LABEL";
export const SEND_RECOVERY_EMAIL_BUTTON_LABEL =
  "SEND_RECOVERY_EMAIL_BUTTON_LABEL";
export const CHANGE_PASSWORD_BUTTON_LABEL = "CHANGE_PASSWORD_BUTTON_LABEL";
export const INVALID_CODE_ERROR_MSG = "INVALID_CODE_ERROR_MSG";

// NewPasswordRequired
export const NEW_PASSWORD_REQUIRED_HEADING = "NEW_PASSWORD_REQUIRED_HEADING";
export const UNKOWN_EMAIL_PLACEHOLDER_TEXT = "UNKOWN_EMAIL_PLACEHOLDER_TEXT";
export const RE_ENTER_NEW_PASSWORD_FIELD_LABEL =
  "RE_ENTER_NEW_PASSWORD_FIELD_LABEL";
export const CREATE_PASSWORD_BUTTON_LABEL = "CREATE_PASSWORD_BUTTON_LABEL";

// QuizMe
export const QUIZ_ME_TITLE = "QUIZ_ME_TITLE";

// routing
export const AUTH_BASE_ROUTE_TEXT = "AUTH_BASE_ROUTE_TEXT";
export const MYCONTENT_BASE_ROUTE_TEXT = "MYCONTENT_BASE_ROUTE_TEXT";
export const LOGIN_ROUTE_TEXT = "LOGIN_ROUTE_TEXT";
export const NEW_PASSWORD_REQUIRED_ROUTE_TEXT =
  "NEW_PASSWORD_REQUIRED_ROUTE_TEXT";
export const FORGOT_PASSWORD_ROUTE_TEXT = "FORGOT_PASSWORD_ROUTE_TEXT";
export const FORGOT_PASSWORD_SUBMIT_ROUTE_TEXT =
  "FORGOT_PASSWORD_SUBMIT_ROUTE_TEXT";
export const REGISTER_ROUTE_TEXT = "REGISTER_ROUTE_TEXT";
export const REGISTER_CONFIRMATION_ROUTE_TEXT =
  "REGISTER_CONFIRMATION_ROUTE_TEXT";
export const SELECTED_VIDEO_ID_ROUTE_TEXT = "SELECTED_VIDEO_ID_ROUTE_TEXT";
export const VIDEO_ROUTE_TEXT = "VIDEO_ROUTE_TEXT";
export const UPLOADS_ROUTE_TEXT = "UPLOADS_ROUTE_TEXT";
export const UPLOADING_VIDEO_ID = "UPLOADING_VIDEO_ID";

// Login
export const REGISTER_BUTTON_LABEL = "REGISTER_BUTTON_LABEL";
export const FORGOT_PASSWORD_QUESTION_MARK_TEXT =
  "FORGOT_PASSWORD_QUESTION_MARK_TEXT";
export const LOGIN_BUTTON_LABEL = "LOGIN_BUTTON_LABEL";
export const NOT_REGISTERED_QUESTION_MARK_TEXT =
  "NOT_REGISTERED_QUESTION_MARK_TEXT";
export const NO_PROBLEM_EXCLAMATION_MARK_TEXT =
  "NO_PROBLEM_EXCLAMATION_MARK_TEXT";
export const LOGIN_SCREEN_REGESTRATION_NUDGE_TEXT =
  "LOGIN_SCREEN_REGESTRATION_NUDGE_TEXT";
export const PASSWORD_SHOULD_BE_AT_LEAST_8_CHARS_ERROR_MSG =
  "PASSWORD_SHOULD_BE_AT_LEAST_8_CHARS_ERROR_MSG";

// Register & RegisterConfirmation
export const REGISTER_HEADING = "REGISTER_HEADING";
export const MISSING_FULL_NAME_ERROR_MSG = "MISSING_FULL_NAME_ERROR_MSG";
export const FULL_NAME_FIELD_LABEL = "FULL_NAME_FIELD_LABEL";
export const FULL_NAME_EXAMPLE_TEXT = "FULL_NAME_EXAMPLE_TEXT";
export const RE_ENTER_PASSWORD_FIELD_LABEL = "RE_ENTER_PASSWORD_FIELD_LABEL";
export const CREATE_ACCOUNT_BUTTON_LABEL = "CREATE_ACCOUNT_BUTTON_LABEL";
export const REGISTER_CONFIRMATION_GUIDE_TEXT =
  "REGISTER_CONFIRMATION_GUIDE_TEXT";
export const REGISTER_CONFIRMATION_GUIDE_TEXT_2 =
  "REGISTER_CONFIRMATION_GUIDE_TEXT_2";
export const REGISTER_CONFIRMATION_GUIDE_TEXT_3 =
  "REGISTER_CONFIRMATION_GUIDE_TEXT_3";
export const INVALID_CODE_LENGTH_ERROR_MSG = "INVALID_CODE_LENGTH_ERROR_MSG";
export const USERNAME_CANT_BE_EMAIL_ADDRESS_ERROR_MSG =
  "USERNAME_CANT_BE_EMAIL_ADDRESS_ERROR_MSG";
export const REGISTER_CONFIRMATION_USERNAME_TEXT =
  "REGISTER_CONFIRMATION_USERNAME_TEXT";

// Transcrript
export const TRANSCRIPT_TITLE = "TRANSCRIPT_TITLE";
export const SEARCH_TRANSCRIPT_FIELD_LABEL = "SEARCH_TRANSCRIPT_FIELD_LABEL";
export const TRANSCRIPT_VIDEO_UPLOADING_STATE_TEXT =
  "TRANSCRIPT_VIDEO_UPLOADING_STATE_TEXT";
export const TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT =
  "TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT";
export const TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT_REFRESH_BOLDED =
  "TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT_REFRESH_BOLDED";
export const REFRESH_BUTTON_LABEL = "REFRESH_BUTTON_LABEL";

// VideoThumbnail
export const UPLOADING_LABEL_TEXT = "UPLOADING_LABEL_TEXT";
export const PROCESSING_LABEL_TEXT = "PROCESSING_LABEL_TEXT";

// VideoUploadButton
export const UPLOAD_MODAL_TITLE = "UPLOAD_MODAL_TITLE";
export const MISSING_NAME_ERROR_MSG = "MISSING_NAME_ERROR_MSG";
export const NAME_SHOULD_BE_AT_LEAST_3_CHARS_ERROR_MSG =
  "NAME_SHOULD_BE_AT_LEAST_3_CHARS_ERROR_MSG";
export const FAILED_TO_CAPTURE_FILE_ERROR_MSG =
  "FAILED_TO_CAPTURE_FILE_ERROR_MSG";
export const INVALID_FILE_ERROR_MSG = "INVALID_FILE_ERROR_MSG";
export const INVALID_FILE_NAME_ERROR_MSG = "INVALID_FILE_NAME";
export const INVALID_FILE_EXTENSION_ERROR_MSG =
  "INVALID_FILE_EXTENSION_ERROR_MSG";
export const FILE_SIZE_OVER_LIMIT_ERROR_MSG = "FILE_SIZE_OVER_LIMIT_ERROR_MSG";
export const NAME_FIELD_LABEL = "NAME_FIELD_LABEL";
export const FILE_NAME_FIELD_LABEL = "FILE_NAME_FIELD_LABEL";
export const UPLOAD_BUTTON_LABEL = "UPLOAD_BUTTON_LABEL";
export const PAGINATION_OF_TEXT = "PAGINATION_OF_TEXT";
export const PAGINATION_PAGE_TEXT = "PAGINATION_PAGE_TEXT";
export const SHARE_YOUR_RESOURCE_SUBHEADING = "SHARE_YOUR_RESOURCE_SUBHEADING";
export const SHARE_WITH_LABEL = "SHARE_WITH_LABEL";
export const EMPTY_SELECTED_SHARE_WITH_GRUOPS_LABEL =
  "EMPTY_SELECTED_SHARE_WITH_GRUOPS_LABEL";
export const UPLOAD_FILE_FIELD_LABEL = "UPLOAD_FILE_FIELD_LABEL";

// VideoMenu
export const PLAYBACK_SPEED_MENU_ITEM = "PLAYBACK_SPEED_MENU_ITEM";
export const NORMAL_PLAYBACK_SPEED_MENU_ITEM =
  "NORMAL_PLAYBACK_SPEED_MENU_ITEM";

// OriginalHeader
export const MISSING_FIRST_NAME_ERROR_MSG = "MISSING_FIRST_NAME_ERROR_MSG";
export const MISSING_LAST_NAME_ERROR_MSG = "MISSING_LAST_NAME_ERROR_MSG";
export const MISSING_EMAIL_ERROR_MSG = "MISSING_EMAIL_ERROR_MSG";
export const INVITE_A_FRIEND_SUCCESS_MSG = "INVITE_A_FRIEND_SUCCESS_MSG";
export const INVITE_A_FRIEND_MENU_ITEM = "INVITE_A_FRIEND_MENU_ITEM";
export const INVITE_A_FRIEND_MODAL_TITLE = "INVITE_A_FRIEND_MODAL_TITLE";
export const INVITE_A_FRIEND_MODAL_GUIDE_TEXT =
  "INVITE_A_FRIEND_MODAL_GUIDE_TEXT";
export const FIRST_NAME_FIELD_LABEL = "FIRST_NAME_FIELD_LABEL";
export const LAST_NAME_FIELD_LABEL = "LAST_NAME_FIELD_LABEL";
export const SEND_BUTTON_EMAIL = "SEND_BUTTON_EMAIL";
export const SELECT_THEME_TITLE = "SELECT_THEME_TITLE";
export const CHANGE_THEME_MENU_ITEM = "CHANGE_THEME_MENU_ITEM";
export const LOGOUT_MENU_ITEM = "LOGOUT_MENU_ITEM";
export const OPEN_LEFT_PANE_ICON_TEXT = "OPEN_LEFT_PANE_ICON_TEXT";
export const CLOSE_LEFT_PANE_ICON_TEXT = "CLOSE_LEFT_PANE_ICON_TEXT";

// MyContent
export const VIDEOS_TITLE = "VIDEOS_TITLE";
export const MY_UPLOADS_TITLE = "MY_UPLOADS_TITLE";
export const KEYWORDS_TITLE = "KEYWORDS_TITLE";
export const VIDEO_TITLE_DEFUALT_TEXT = "Video Title";
export const VIDEO_UPLOADING_PERCENTAGE_PHRASE =
  "VIDEO_UPLOADING_PERCENTAGE_PHRASE";
export const KEYWORDS_VIDEO_UPLOADING_STATE_TEXT =
  "KEYWORDS_VIDEO_UPLOADING_STATE_TEXT";
export const KEYWORDS_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT =
  "KEYWORDS_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT";

// MyUploads
export const PREVIEW_COLUMN_LABEL = "PREVIEW_COLUMN_LABEL";
export const SHARED_WITH_COLUMN_LABEL = "SHARED_WITH_COLUMN_LABEL";
export const RESOURCE_COLUMN_LABEL = "RESOURCE_COLUMN_LABEL";
export const VIDEO_DELETE_SUCCESS_MSG = "VIDEO_DELETE_SUCCESS_MSG";
export const VIDEO_DELETE_FAILURE_MSG = "VIDEO_DELETE_FAILURE_MSG";
export const VIDEO_DELETE_TITLE_TEXT = "VIDEO_DELETE_TITLE_TEXT";
export const VIDEO_DELETE_CONFIRMATION_MSG = "VIDEO_DELETE_CONFIRMATION_MSG";
export const VIDEO_DELETE_TABLE_ARIA_LABEL = "VIDEO_DELETE_TABLE_ARIA_LABEL";

// OrbotAvatar
export const ORBOT_AVATAR_ALT = "ORBOT_AVATAR_ALT";

// Common
export const CANCEL_BUTTON_LABEL = "CANCEL_BUTTON_LABEL";
export const OK_BUTTON_LABEL = "OK_BUTTON_LABEL";
export const DELETE_BUTTON_LABEL = "DELETE_BUTTON_LABEL";
export const STEP_1_OF_2_LABEL = "STEP_1_OF_2";
export const STEP_2_OF_2_LABEL = "STEP_2_OF_2";
export const DEFAULT_ERROR_MSG = "DEFAULT_ERROR_MSG";
export const MISSING_USERNAME_ERROR_MSG = "MISSING_USERNAME_ERROR_MSG";
export const INVALID_USERNAME_WITH_SPACE_ERROR_MSG =
  "INVALID_USERNAME_WITH_SPACE_ERROR_MSG";
export const MISSING_PASSWORD_ERROR_MSG = "MISSING_PASSWORD_ERROR_MSG";
export const USERNAME_FIELD_LABEL = "USERNAME_FIELD_LABEL";
export const PASSWORD_FIELD_LABEL = "PASSWORD_FIELD_LABEL";
export const EMAIL_FIELD_LABEL = "EMAIL_FIELD_LABEL";
export const NEW_PASSWORD_FILED_LABEL = "NEW_PASSWORD_FILED_LABEL";
export const NEW_PASSWORD_FILED_LABEL_2 = "NEW_PASSWORD_FILED_LABEL_2";
export const PASSWORDS_DONT_MATCH_ERROR_MSG = "PASSWORDS_DONT_MATCH_ERROR_MSG";
export const MISSING_CODE_ERROR_MSG = "MISSING_CODE_ERROR_MSG";

// utils
export const PASSWORD_SHOULD_BE_AT_LEAST_ONE_NUMBER =
  "PASSWORD_SHOULD_BE_AT_LEAST_ONE_NUMBER";
export const PASSWORD_SHOULD_BE_AT_LEAST_ONE_LOWERCASE_CHARACTER =
  "PASSWORD_SHOULD_BE_AT_LEAST_ONE_LOWERCASE_CHARACTER";
export const PASSWORD_SHOULD_BE_AT_LEAST_ONE_UPPERCASE_CHARACTER =
  "PASSWORD_SHOULD_BE_AT_LEAST_ONE_UPPERCASE_CHARACTER";
export const PASSWORD_SHOULD_BE_AT_LEAST_ONE_SPECIAL_CHARACTER =
  "PASSWORD_SHOULD_BE_AT_LEAST_ONE_SPECIAL_CHARACTER";
export const INVALID_EMAIL_ERROR_MSG = "INVALID_EMAIL_ERROR_MSG";
export const USERNAME_TAKEN_ERROR_MSG = "USERNAME_TAKEN_ERROR_MSG";
export const INVALID_USERNAME_OR_PASSWORD_ERROR_MSG =
  "INVALID_USERNAME_OR_PASSWORD_ERROR_MSG";
export const EXPIRED_CODE_OTP_ERROR_MSG = "EXPIRED_CODE_ERROR_MSG";
export const EXPIRED_CODE_ERROR_MSG_1 = "EXPIRED_CODE_ERROR_MSG_1";
export const EXPIRED_CODE_ERROR_MSG_2 = "EXPIRED_CODE_ERROR_MSG_2";
export const EXPIRED_CODE_ERROR_MSG_3 = "EXPIRED_CODE_ERROR_MSG_3";
export const EXPIRED_CODE_ERROR_MSG_4 = "EXPIRED_CODE_ERROR_MSG_4";
export const EXPIRED_CODE_ERROR_MSG_5 = "EXPIRED_CODE_ERROR_MSG_5";
export const CODE_MISMATCH_EXCEPTION_1 = "CODE_MISMATCH_EXCEPTION_1";
export const CODE_MISMATCH_EXCEPTION_2 = "CODE_MISMATCH_EXCEPTION_2";
export const CODE_MISMATCH_EXCEPTION_3 = "CODE_MISMATCH_EXCEPTION_3";
export const NETWORK_ERROR_MSG = "NETWORK_ERROR_MSG";

export const en = {
  //ErrorBoundary
  [ERROR_BOUNDARY_MSG]: "Something went wrong 💥.",

  // ForgotPassword & ForgotPasswordSubmit
  [RECOVER_YOUR_PASSWORD_HEADING]: "Recover your password",
  [CHANGE_YOUR_PASSWORD_HEADING]: "Change your password",
  [FORGOT_PASSWORD_GUIDE_TEXT]:
    "Enter in your username or email address. An email will be sent to the associated email address with a confirmation code to verify your account.",
  [FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT]:
    "We have sent an ORBITS confirmation code to the account's email address, if we have one on file. Please check your inbox as well as your spam folder. Contact help@yourorbits.com if you don't receive an email.",
  [FORGOT_PASSWORD_SUBMIT_GUIDE_TEXT_2]:
    "You will be redirected to the login page after changing your password.",
  [USERNAME_OR_EMAIL_FIELD_LABEL]: "Username or email address (Required)",
  [CONFIRMATION_CODE_FILED_LABEL]: "Confirmation code (Required)",
  [SEND_RECOVERY_EMAIL_BUTTON_LABEL]: "Send recovery email",
  [CHANGE_PASSWORD_BUTTON_LABEL]: "Change password",
  [INVALID_CODE_ERROR_MSG]: "Invalid code; Valid code consist of 6 digits",

  // NewPasswordRequired
  [NEW_PASSWORD_REQUIRED_HEADING]:
    "Email address / username associated with new password",
  [UNKOWN_EMAIL_PLACEHOLDER_TEXT]: "Unkown email",
  [RE_ENTER_NEW_PASSWORD_FIELD_LABEL]: "Re-enter new password (Required)",
  [CREATE_PASSWORD_BUTTON_LABEL]: "Create password",

  // QuizMe
  [QUIZ_ME_TITLE]: "Quiz Me",

  // routing
  [AUTH_BASE_ROUTE_TEXT]: "auth",
  [MYCONTENT_BASE_ROUTE_TEXT]: "mycontent",
  [LOGIN_ROUTE_TEXT]: "login",
  [NEW_PASSWORD_REQUIRED_ROUTE_TEXT]: "new-password-required",
  [FORGOT_PASSWORD_ROUTE_TEXT]: "forgot-password",
  [FORGOT_PASSWORD_SUBMIT_ROUTE_TEXT]: "forgot-password-submit",
  [REGISTER_ROUTE_TEXT]: "register",
  [REGISTER_CONFIRMATION_ROUTE_TEXT]: "register-confrimation",
  [SELECTED_VIDEO_ID_ROUTE_TEXT]: "selectedResourceId",
  [VIDEO_ROUTE_TEXT]: "videos",
  [UPLOADS_ROUTE_TEXT]: "uploads",
  [UPLOADING_VIDEO_ID]: "uploading",

  // Login
  [REGISTER_BUTTON_LABEL]: "Register",
  [FORGOT_PASSWORD_QUESTION_MARK_TEXT]: "Forgot password?",
  [LOGIN_BUTTON_LABEL]: "Log in",
  [NOT_REGISTERED_QUESTION_MARK_TEXT]: "Not registered? ",
  [NO_PROBLEM_EXCLAMATION_MARK_TEXT]: " No problem!",
  [LOGIN_SCREEN_REGESTRATION_NUDGE_TEXT]:
    "If you are interested in smart transcriptions and search tools for your video lessons you can register for ORBITS.",
  [PASSWORD_SHOULD_BE_AT_LEAST_8_CHARS_ERROR_MSG]:
    "Password should be at least 8 characters",

  // Register & RegisterConfirmation
  [REGISTER_HEADING]: "Register for ORBITS",
  [MISSING_FULL_NAME_ERROR_MSG]: "Missing Full name",
  [FULL_NAME_FIELD_LABEL]: "Full name (Required)",
  [FULL_NAME_EXAMPLE_TEXT]: "An example of a full name is William Shakespeare.",
  [RE_ENTER_PASSWORD_FIELD_LABEL]: "Re-enter password (Required)",
  [CREATE_ACCOUNT_BUTTON_LABEL]: "Create account",
  [REGISTER_CONFIRMATION_GUIDE_TEXT]:
    "Thank you for registering with us! We have sent you a confirmation code to verify your ORBITS account to your email address. Check your spam folder if you can't locate it.",
  [REGISTER_CONFIRMATION_GUIDE_TEXT_2]:
    "Simply enter in the confirmation code from that email message, and select “Confirm account”.",
  [REGISTER_CONFIRMATION_GUIDE_TEXT_3]:
    "You will be redirected to the login page after registering your account.",
  [INVALID_CODE_LENGTH_ERROR_MSG]: (codeLength: number) =>
    `Code consist of 6 chars, currently ${codeLength} chars in input`,
  [USERNAME_CANT_BE_EMAIL_ADDRESS_ERROR_MSG]:
    "The username can't be an email address",
  [REGISTER_CONFIRMATION_USERNAME_TEXT]: "Username",

  // Transcrript
  [TRANSCRIPT_TITLE]: "Transcript",
  [SEARCH_TRANSCRIPT_FIELD_LABEL]: "Search Transcript",
  [TRANSCRIPT_VIDEO_UPLOADING_STATE_TEXT]:
    "The transcript for this video will begin processing when the upload is complete.",
  [TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT]:
    "Your transcription is in process. Check back in a couple of minutes and press",
  [TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT_REFRESH_BOLDED]:
    " Refresh!",
  [REFRESH_BUTTON_LABEL]: "Refresh",

  [UPLOADING_LABEL_TEXT]: "Uploading",
  [PROCESSING_LABEL_TEXT]: "Processing",

  // VideoUploadButton
  [UPLOAD_MODAL_TITLE]: "Upload a resource",
  [MISSING_NAME_ERROR_MSG]: "Missing name",
  [NAME_SHOULD_BE_AT_LEAST_3_CHARS_ERROR_MSG]:
    "Name should be at least 3 characters",
  [FAILED_TO_CAPTURE_FILE_ERROR_MSG]: "Failed to capture file",
  [INVALID_FILE_ERROR_MSG]: "Invalid file",
  [INVALID_FILE_NAME_ERROR_MSG]: "Invalid file name",
  [INVALID_FILE_EXTENSION_ERROR_MSG]: (
    fileExtension: string,
    validFileExtensions: string
  ) =>
    `Invalid file extension: ${fileExtension}. You may upload one of ${validFileExtensions} file format.`,
  [FILE_SIZE_OVER_LIMIT_ERROR_MSG]: (overLimitInGB: number) =>
    `File size must be less than 2 GB, your file size is currently ${overLimitInGB} GB over the limit.`,
  [NAME_FIELD_LABEL]: "Name this content (Required)",
  [FILE_NAME_FIELD_LABEL]:
    "Drag and drop file here or click to upload from your computer.",
  [UPLOAD_BUTTON_LABEL]: "Upload",
  [PAGINATION_OF_TEXT]: "of",
  [PAGINATION_PAGE_TEXT]: "Page",
  [SHARE_YOUR_RESOURCE_SUBHEADING]: "Where should this Content go?",
  [SHARE_WITH_LABEL]: "Share with",
  [EMPTY_SELECTED_SHARE_WITH_GRUOPS_LABEL]:
    "Select a group to share your video with others.",
  [UPLOAD_FILE_FIELD_LABEL]: "Upload file (Required)",

  // VideoMenu
  [PLAYBACK_SPEED_MENU_ITEM]: "Playback Speed",
  [NORMAL_PLAYBACK_SPEED_MENU_ITEM]: "Normal",

  // OriginalHeader
  [MISSING_FIRST_NAME_ERROR_MSG]: "Missing first name",
  [MISSING_LAST_NAME_ERROR_MSG]: "Missing last name",
  [MISSING_EMAIL_ERROR_MSG]: "Missing email",
  [INVITE_A_FRIEND_SUCCESS_MSG]: (email: string) =>
    `Successfully sent an invitaition to ${email}.`,
  [INVITE_A_FRIEND_MENU_ITEM]: "Invite a Friend",
  [INVITE_A_FRIEND_MODAL_TITLE]: "Invite someone you know!",
  [INVITE_A_FRIEND_MODAL_GUIDE_TEXT]:
    'Fill in a bit of information and press "Send" to request an invite for your collaborators!',
  [FIRST_NAME_FIELD_LABEL]: "First Name (Required)",
  [LAST_NAME_FIELD_LABEL]: "Last Name (Required)",
  [SEND_BUTTON_EMAIL]: "Send",
  [SELECT_THEME_TITLE]: "Select a theme",
  [CHANGE_THEME_MENU_ITEM]: "Change theme",
  [LOGOUT_MENU_ITEM]: "Logout",
  [OPEN_LEFT_PANE_ICON_TEXT]: "Open left pane",
  [CLOSE_LEFT_PANE_ICON_TEXT]: "Close left pane",

  // MyContent
  [VIDEOS_TITLE]: "Videos",
  [MY_UPLOADS_TITLE]: "My Uploads",
  [KEYWORDS_TITLE]: "Keywords",
  [VIDEO_TITLE_DEFUALT_TEXT]: "Video Title",
  [VIDEO_UPLOADING_PERCENTAGE_PHRASE]: (uploadPercent: number) =>
    ` (Uploading at ${uploadPercent}%)`,
  [KEYWORDS_VIDEO_UPLOADING_STATE_TEXT]:
    "The keywords for this video will begin processing when the upload is complete.",
  [KEYWORDS_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT]:
    "Your keywords are in progress. Check back in couple of minutes and refresh the page.",

  // MyUploads
  [PREVIEW_COLUMN_LABEL]: "Preview",
  [SHARED_WITH_COLUMN_LABEL]: "Shared with",
  [RESOURCE_COLUMN_LABEL]: "Resource",
  [VIDEO_DELETE_SUCCESS_MSG]: (count: number) =>
    `${count} videos deleted successfully.`,
  [VIDEO_DELETE_FAILURE_MSG]: (count: number) =>
    `${count} videos failed to delete. For assistance please contact help@yourorbits.com.`,
  [VIDEO_DELETE_TITLE_TEXT]: (count: number) => `Delete ${count} videos`,
  [VIDEO_DELETE_CONFIRMATION_MSG]: (count: number) =>
    `Are you sure you want to delete following ${count} videos?`,
  [VIDEO_DELETE_TABLE_ARIA_LABEL]:
    "table listing all the videos selected to be deleted",

  // OrbotAvatar
  [ORBOT_AVATAR_ALT]: "Orbot Avatar",

  // Common
  [CANCEL_BUTTON_LABEL]: "Cancel",
  [OK_BUTTON_LABEL]: "OK",
  [DELETE_BUTTON_LABEL]: "Delete",
  [STEP_1_OF_2_LABEL]: "Step 1 of 2",
  [STEP_2_OF_2_LABEL]: "Step 2 of 2",
  [DEFAULT_ERROR_MSG]:
    "An unexpected error occurred. Please reload the page and try again. Contact help@yourorbits.com if the issue persists.",
  [MISSING_USERNAME_ERROR_MSG]: "Missing username",
  [INVALID_USERNAME_WITH_SPACE_ERROR_MSG]:
    "Invalid username: username cannot contain spaces, tabs, or other whitespace",
  [MISSING_PASSWORD_ERROR_MSG]: "Missing password",
  [PASSWORDS_DONT_MATCH_ERROR_MSG]: "Passwords don't match",
  [NEW_PASSWORD_FILED_LABEL]: "New password (Required)",
  [NEW_PASSWORD_FILED_LABEL_2]: "Confirm new password (Required)",
  [USERNAME_FIELD_LABEL]: "Username (Required)",
  [EMAIL_FIELD_LABEL]: "Email (Required)",
  [PASSWORD_FIELD_LABEL]: "Password (Required)",
  [MISSING_CODE_ERROR_MSG]: "Missing code",

  // utils
  [PASSWORD_SHOULD_BE_AT_LEAST_ONE_NUMBER]:
    "Password should contain at least one number",
  [PASSWORD_SHOULD_BE_AT_LEAST_ONE_LOWERCASE_CHARACTER]:
    "Password should contain at least one lowercase character",
  [PASSWORD_SHOULD_BE_AT_LEAST_ONE_UPPERCASE_CHARACTER]:
    "Password should contain at least one uppercase character",
  [PASSWORD_SHOULD_BE_AT_LEAST_ONE_SPECIAL_CHARACTER]:
    "Password should contain at least one special character",
  [INVALID_EMAIL_ERROR_MSG]: "Invalid email",
  [USERNAME_TAKEN_ERROR_MSG]: "Username taken, please try another username.",
  [INVALID_USERNAME_OR_PASSWORD_ERROR_MSG]:
    "Incorrect username or password. Please try again.",
  [EXPIRED_CODE_OTP_ERROR_MSG]:
    "Oh no, your confirmation code is expired! Contact help@yourorbits.com if the issue persists.",
  [EXPIRED_CODE_ERROR_MSG_1]:
    "Oh no, your confirmation code is expired! You may request another code by selecting ",
  [EXPIRED_CODE_ERROR_MSG_2]: "Cancel",
  [EXPIRED_CODE_ERROR_MSG_3]:
    " on this page, and then request another code by selecting ",
  [EXPIRED_CODE_ERROR_MSG_4]: "Forgot password?",
  [EXPIRED_CODE_ERROR_MSG_5]:
    " Contact help@yourorbits.com if the issue persists.",
  [CODE_MISMATCH_EXCEPTION_1]: "The ",
  [CODE_MISMATCH_EXCEPTION_2]: "Confirmation code",
  [CODE_MISMATCH_EXCEPTION_3]: " is incorrect, please try again.",
  [NETWORK_ERROR_MSG]:
    "We cannot reach your network! Check your internet connection, and if the problem persists please contact your internet provider.",
};
