import { AppState } from "@aws-amplify/core";
import { AlertColor } from "@mui/lab";
// https://github.com/facebook/create-react-app/issues/7255
// eslint-disable-next-line
import { AsTyped } from "as-typed";
import { ClassNameMap } from "@mui/styles";
import { SearchCommandOutput } from "@aws-sdk/client-cloudsearch-domain";

import {
  GetVideoTranscriptResponseSchema,
  GetUploadsResponseSchema,
  GetVideoSharesResponseSchema,
  PostVideoResponseSchema,
  StringResponseSchema,
} from "@orbits/lib-videos-api-schemas";
import {
  GetGroupsResponseSchema,
  GetGroupSharesResponseSchema,
  GetGroupSubgroupingsResponseSchema,
} from "@orbits/lib-groups-api-schemas";

export const OrbitsConfigSchema = {
  $schema: "http://json-schema.org/draft-04/schema#",
  type: "object",
  required: [
    "awsCognito",
    "endpointV2",
    "searchEndpoint",
    "enableNotifications",
    "notificationDetails",
    "smartnEndpoint",
  ],
  properties: {
    awsCognito: {
      type: "object",
      required: [
        "aws_project_region",
        "aws_user_pools_id",
        "aws_user_pools_web_client_id",
        "authenticationFlowType",
      ],
      properties: {
        aws_project_region: {
          type: "string",
          minLength: 1,
        },
        aws_user_pools_id: {
          type: "string",
          minLength: 1,
        },
        aws_user_pools_web_client_id: {
          type: "string",
          minLength: 1,
        },
        authenticationFlowType: {
          type: "string",
          minLength: 1,
        },
      },
    },
    endpointV2: {
      type: "string",
      minLength: 1,
    },
    smartnEndpoint: {
      type: "string",
      minLength: 1,
    },
    searchEndpoint: {
      type: "string",
    },
    enableNotifications: {
      type: "boolean",
    },
    notificationDetails: {
      type: "object",
      required: ["demoVideoId"],
      properties: {
        demoVideoId: {
          type: "string",
        },
      },
    },
  },
} as const;

export type OrbitsConfig = AsTyped<typeof OrbitsConfigSchema>;

export type GetVideoTranscriptResponse = AsTyped<
  typeof GetVideoTranscriptResponseSchema
>;

export type GetUploadsResponse = AsTyped<typeof GetUploadsResponseSchema>;

export type GetGroupSharesResponse = AsTyped<
  typeof GetGroupSharesResponseSchema
>;

export type GetGroupsResponse = AsTyped<typeof GetGroupsResponseSchema>;

export type StringResponse = AsTyped<typeof StringResponseSchema>;

export type PostVideoResponse = AsTyped<typeof PostVideoResponseSchema>;

export type GetGroupSubgroupingsResponse = AsTyped<
  typeof GetGroupSubgroupingsResponseSchema
>;

export const PutEventsResponseSchema = {
  $schema: "http://json-schema.org/draft-04/schema#",
  type: "object",
  required: ["$metadata", "Entries"],
  properties: {
    $metadata: {
      type: "object",
      required: ["httpStatusCode", "requestId", "attempts", "totalRetryDelay"],
      properties: {
        httpStatusCode: {
          type: "number",
          minimum: 100,
          maximum: 599,
        },
        requestId: {
          type: "string",
          minLength: 1,
        },
        attempts: {
          type: "number",
          minimum: 1,
        },
        totalRetryDelay: {
          type: "number",
          minimum: 0,
        },
      },
    },
    Entries: {
      type: "array",
      items: {
        type: "object",
        required: ["EventId"],
        properties: {
          EventId: {
            type: "string",
            minLength: 1,
          },
        },
      },
    },
  },
} as const;

export type PutEventsResponse = AsTyped<typeof PutEventsResponseSchema>;

export type GetVideoSharesResponse = AsTyped<
  typeof GetVideoSharesResponseSchema
>;

export interface Group {
  readonly id: string;
  readonly displayName: string;
  childGroups?: Group[];
  videos?: Resource[];
}

export class Tree {
  static addGroups(currGroup: Group, groupId: string, subgroups: Group[]) {
    // console.log(`addGroups(${groupId})`, currGroup);
    const newChildGroups = [...(currGroup.childGroups || [])];
    if (currGroup.id === groupId) {
      subgroups.forEach(
        (subgroup) =>
          // This is to make sure that the subgroup is not added twice.
          // JS Set doesn't seem to have a easy way to give it custom function to compare objects.
          newChildGroups.filter((ncg) => ncg.id === subgroup.id).length === 0 &&
          newChildGroups.push(subgroup)
      );
    }
    currGroup.childGroups = newChildGroups;

    currGroup.childGroups.forEach((g) => Tree.addGroups(g, groupId, subgroups));
  }

  static addVideos(currGroup: Group, groupId: string, videos: Resource[]) {
    if (currGroup.id === groupId) {
      const newVideos = [...(currGroup.videos || [])];
      videos.forEach(
        (video) =>
          // This is to make sure that the video is not added twice.
          // JS Set doesn't seem to have a easy way to give it custom function to compare objects.
          newVideos.filter((nv) => nv.id === video.id).length === 0 &&
          newVideos.push(video)
      );
      currGroup.videos = newVideos;
    }
    if (currGroup.childGroups) {
      currGroup.childGroups.forEach((g) => Tree.addVideos(g, groupId, videos));
    }
  }

  static findGroupPathById(currGroup: Group, groupId: string): Group[] {
    if (currGroup.id === groupId) {
      return [currGroup];
    }
    if (currGroup.childGroups) {
      for (const g of currGroup.childGroups) {
        const found = Tree.findGroupPathById(g, groupId);
        if (found.length) {
          return [currGroup, ...found];
        }
      }
    }
    return [];
  }

  static findGroupById(currGroup: Group, groupId: string): Group | undefined {
    if (currGroup.id === groupId) {
      return currGroup;
    }
    if (currGroup.childGroups) {
      for (const g of currGroup.childGroups) {
        const found = Tree.findGroupById(g, groupId);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  static findVideoById(
    currGroup: Group,
    videoId: string
  ): Resource | undefined {
    if (currGroup.videos) {
      for (const v of currGroup.videos) {
        if (v.id === videoId) {
          return v;
        }
      }
    }
    if (currGroup.childGroups) {
      for (const g of currGroup.childGroups) {
        const found = Tree.findVideoById(g, videoId);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  static updateVideoUploadPercent(
    currGroup: Group,
    videoId: string,
    percent: number
  ) {
    if (currGroup.videos) {
      currGroup.videos = currGroup.videos.map((v) => ({
        ...v,
        uploadPercent: v.id === videoId ? percent : v.uploadPercent,
      }));
    }
    if (currGroup.childGroups) {
      for (const g of currGroup.childGroups) {
        Tree.updateVideoUploadPercent(g, videoId, percent);
      }
    }
  }

  static removeAllUploadingVideos(currGroup: Group) {
    if (currGroup.videos) {
      const newVideos = currGroup.videos.filter(
        (v) => v.uploadPercent === undefined
      );
      currGroup.videos = newVideos;
    }
    if (currGroup.childGroups) {
      for (const g of currGroup.childGroups) {
        Tree.removeAllUploadingVideos(g);
      }
    }
  }

  static removeResources(currGroup: Group, resources: string[]) {
    if (currGroup.videos) {
      const newVideos = currGroup.videos.filter(
        (v) => !resources.includes(v.id)
      );
      currGroup.videos = newVideos;
    }
    if (currGroup.childGroups) {
      for (const g of currGroup.childGroups) {
        Tree.removeResources(g, resources);
      }
    }
  }
  // static addThumbnail(currGroup: Group, videoId: string, thumbnailUrl: string) {
  //   (currGroup.videos || []).forEach(
  //     (v) => v.id === videoId && (v.thumbnailUrl = thumbnailUrl)
  //   );
  //   if (currGroup.childGroups) {
  //     currGroup.childGroups.forEach((g) =>
  //       Tree.addThumbnail(g, videoId, thumbnailUrl)
  //     );
  //   }
  // }

  // static removeAllThumbnails(currGroup: Group) {
  //   (currGroup.videos || []).forEach((v) => v.thumbnailUrl = "");
  //   if (currGroup.childGroups) {
  //     currGroup.childGroups.forEach((g) => Tree.removeAllThumbnails(g));
  //   }
  // }
}

export type ResourceType = "audio" | "video" | "pdf";
export const resourceTypes: ResourceType[] = ["audio", "video", "pdf"];

export class Resource {
  readonly id: string;
  readonly title: string;
  readonly ctime: string;
  readonly creator: string;
  readonly uploadPercent: number | undefined;
  readonly sharedWith: Group[] | undefined;
  readonly type: ResourceType;

  constructor(
    input:
      | GetUploadsResponse[number]
      | GetGroupSharesResponse[number]
      | {
          id: string;
          title: string;
          ctime: string;
          creator: string;
          uploadPercent: number | undefined;
          sharedWith: Group[] | undefined;
          type: ResourceType | undefined;
        }
  ) {
    if ("sharedWith" in input) {
      this.id = input.id;
      this.title = input.title;
      this.ctime = input.ctime;
      this.creator = input.creator;
      this.uploadPercent = input.uploadPercent;
      this.sharedWith = input.sharedWith;
      this.type = input.type || "video";
    } else if ("uploader" in input) {
      this.id = input.id;
      this.title = input.title;
      this.ctime = input.ctime;
      this.creator = input.uploader.name;
      this.uploadPercent = undefined;
      this.sharedWith = undefined;
      this.type = input.type || "video";
    } else {
      this.id = input.video.id;
      this.title = input.video.title;
      this.ctime = input.ctime;
      this.creator = input.creator.name;
      this.uploadPercent = undefined;
      this.sharedWith = undefined;
      this.type = input.video.type || "video";
    }
  }

  toString() {
    return `[Video id=${this.id} title=${this.title} ctime=${this.ctime} creator=${this.creator}, uploadPercent=${this.uploadPercent}, sharedWith=${this.sharedWith}, type=${this.type}]`;
  }
}

export interface QuestionType {
  id: string;
  topic: string;
  question: string;
  answers: string[];
  correctAnswer: string;
  userAnswer: string;
}

export type OrbitsVideoEventDetailType =
  | "ORBITS Video Paused"
  | "ORBITS Video Played"
  | "ORBITS Video Seeked";

export type OrbitsEventDetailType =
  | OrbitsVideoEventDetailType
  | "ORBITS User Logged In"
  | "ORBITS Page Loaded"
  | "ORBITS Friend Invited"
  | "ORBITS Keywords Pressed"
  | "ORBITS Transcript Searched";

export type videoEventsType = "pause" | "playing" | "seeked";

export type OrbitsEventType =
  | {
      detailType: "ORBITS Page Loaded";
      detail: { pagePath: string; loadTime?: number };
    }
  | {
      detailType: "ORBITS Friend Invited";
      detail: {
        recipient: { firstName: string; lastName: string; email: string };
      };
    }
  | {
      detailType: "ORBITS Keywords Pressed";
      detail: {
        keywords: string;
        keywordsStartTime: number;
        videoId: string;
      };
    }
  | {
      detailType: "ORBITS Transcript Searched";
      detail: {
        searchText: string;
        videoId?: string;
      };
    }
  | {
      detailType: OrbitsVideoEventDetailType;
      detail: {
        startTime?: number;
        videoId?: string;
        groupId?: string;
      };
    }
  | {
      detailType: "ORBITS User Logged In";
    };

export interface QuizResult {
  questionsCount: number;
  answeredCount: number;
  answeredCorrectlyCount: number;
}

export const defaultQuizResult: QuizResult = {
  questionsCount: 0,
  answeredCount: 0,
  answeredCorrectlyCount: 0,
};

export interface QuizResultTopic {
  total: QuizResult;
  topicAggregate: {
    [topic: string]: QuizResult;
  };
}

export const defaultQuizResultTopic: QuizResultTopic = {
  total: { ...defaultQuizResult },
  topicAggregate: {},
};

export type QuizResultTopicAggregate = QuizResultTopic["topicAggregate"];

export enum AuthState {
  "LOGGED_OUT" = "LOGGED_OUT",
  "LOGGED_IN" = "LOGGED_IN",
  "NEW_PASSWORD_REQUIRED" = "NEW_PASSWORD_REQUIRED",
  "FORGOT_PASSWORD" = "FORGOT_PASSWORD",
  "FORGOT_PASSWORD_SUBMIT" = "FORGOT_PASSWORD_SUBMIT",
  "REGISTER" = "REGISTER",
  "REGISTER_CONFIRMATION" = "REGISTER_CONFIRMATION",
}

export type QuizScreen = "intro" | "quiz" | "results";

export type AppState = {
  authState: AuthState;
  cognitioUser:
    | {
        username: string;
        idToken: string;
        refreshToken: string;
        attributes: {
          [key: string]: string;
        };
      }
    | undefined;
  selectedResourceId: string;
  selectedGroupId: string;
  uploads: Resource[] | undefined; // needs better loading state
  videoAtSeconds: number;
  isSnackbarOpen: boolean;
  snackbarMessage: string;
  snackbarSeverity: AlertColor;
  breadcrumbs: Group[];
  quizScreen: QuizScreen;
  quizResults: QuizResultTopic[];
  sharableGroups: Group[] | undefined;
  tree: Group; // needs loading state
  globalSearchResults: SearchCommandOutput | undefined;
};

export type Action =
  | { type: "NO_OP" } // No Operation
  | { type: "LOGIN"; payload: AppState["cognitioUser"] }
  | { type: "LOGOUT" }
  | { type: "NEW_PASSWORD_REQUIRED" }
  | { type: "REFRESH_ID_TOKEN"; payload: { idToken: string } }
  | { type: "FORGOT_PASSWORD" }
  | { type: "FORGOT_PASSWORD_SUBMIT" }
  | { type: "REGISTER" }
  | { type: "REGISTER_CONFIRMATION" }
  | { type: "SET_SELECTED_VIDEO_ID"; payload: string }
  | { type: "SET_SELECTED_GROUP_ID"; payload: string }
  | { type: "SEEK_VIDEO"; payload: number }
  | { type: "SET_UPLOADS"; payload: GetUploadsResponse }
  | {
      type: "OPEN_SNACKBAR";
      payload: { message: string; severity: AlertColor };
    }
  | { type: "CLOSE_SNACKBAR" }
  | { type: "SET_QUIZ_SCREEN"; payload: QuizScreen }
  | { type: "ADD_QUIZ_RESULT"; payload: QuizResultTopic }
  | { type: "SET_SHARABLE_GROUPS"; payload: Group[] }
  | {
      type: "ADD_GROUPS_TO_TREE";
      payload: { response: GetGroupSubgroupingsResponse; groupId: string };
    }
  | {
      type: "ADD_VIDEOS_TO_TREE";
      payload: { response: GetGroupSharesResponse; groupId: string };
    }
  | { type: "START_VIDEO_UPLOAD"; payload: Resource }
  | {
      type: "UPDATE_VIDEO_UPLOAD_PERCENT";
      payload: { videoId: string; newPercent: number };
    }
  | {
      type: "ADD_SHARED_WITH_TO_UPLOADS";
      payload: { response: GetVideoSharesResponse; videoId: string };
    }
  | {
      type: "ADD_THUMBNAIL_TO_VIDEO";
      payload: { response: StringResponse; videoId: string };
    }
  | {
      type: "REMOVE_RESOURCES";
      payload: string[];
    }
  | {
      type: "SET_GLOBAL_SEARCH_RESULTS";
      payload: SearchCommandOutput;
    };

export type AppStateHistory = {
  action: Action;
  updatedStateKeys: string[];
  state: AppState;
}[];

export interface OrbitsAppContext extends AppState {
  dispatch: React.Dispatch<Action>;
  eventApi: (
    event: OrbitsEventType,
    throwError?: boolean
  ) => Promise<PutEventsResponse>;
  logoutUser: () => void;
  loginUser: (payload: AppState["cognitioUser"]) => void;
  seekVideo: (seconds: number, play?: boolean) => void;
  setSelectedResource: (newVideoId: string) => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  openSnackbar: (severity: AlertColor, message: string) => void;
  closeSnackbar: () => void;
  fetchUploads: () => void;
  fetchGroupShares: (groupId: string) => void;
  // userHasSeenQuizeMeTour: (props: CallBackProps) => void;
  setSelectedGroup: (group: Group) => void;
  setQuizScreen: (screen: QuizScreen) => void;
  addQuizResult: (quizResultTopic: QuizResultTopic) => void;
  startVideoUpload: (video: Resource) => void;
  setUploadPercent: (videoId: string, percent: number) => void;
  deleteVideo: (videoId: string) => Promise<void>;
  getTranscript: (
    videoId: string
  ) => {
    fetch: () => Promise<GetVideoTranscriptResponse>;
    cancel: () => void;
  };
  shareVideo: (videoId: string, groupId: string) => Promise<void>;
  createS3Url: (
    name: string
  ) => Promise<{ fields: { [key: string]: string }; url: string }>;
  fetchShareableGroups: () => void;
  getMedia: (
    videoId: string
  ) => { fetch: () => Promise<string>; cancel: () => void };
  fetchThumbnail: (
    videoId: string
  ) => { fetch: () => Promise<string>; cancel: () => void };
  fetchSubgroups: (groupId: string) => Promise<Group[]>;
  fetchTree: () => void;
  findVideoByIdInTree: (videoId: string) => Resource | undefined;
  findVideoByIdInTreeAndUploads: (videoId: string) => Resource | undefined;
  fetchSharedWithForResourceId: (resourceId: string) => void;
  removeResources: (resourceIds: string[]) => void;
  resourceIndexApi: (query: string, responseType: ResourceType[]) => void;
}

export const defaultState: AppState = {
  authState: AuthState.LOGGED_OUT,
  cognitioUser: undefined,
  selectedResourceId: "",
  selectedGroupId: "c464f2b0-b8bf-11eb-b4bf-5960ee0b3fb3",
  uploads: undefined,
  videoAtSeconds: 0,
  isSnackbarOpen: false,
  snackbarMessage: "",
  snackbarSeverity: "success",
  breadcrumbs: [
    {
      id: "c464f2b0-b8bf-11eb-b4bf-5960ee0b3fb3",
      displayName: "Orbits",
    },
  ],
  quizScreen: "intro",
  quizResults: [],
  sharableGroups: undefined,
  tree: {
    id: "c464f2b0-b8bf-11eb-b4bf-5960ee0b3fb3",
    displayName: "Orbits",
  },
  globalSearchResults: undefined,
};

export type LocalStorageAppState = Pick<
  AppState,
  "authState" | "cognitioUser" | "quizResults"
>;

export interface StorageWithTTL<ValueType> {
  get: () => ValueType | undefined;
  set: (value: ValueType) => void;
  clear: () => void;
}

export interface CacheTTL<ValueType> {
  get: (key: string) => ValueType | undefined;
  set: (key: string, value: ValueType) => void;
  clear: () => void;
}

declare const OrbitsWindow: Window &
  typeof globalThis & {
    config: OrbitsConfig;
  };
export type OrbitsWindowType = typeof OrbitsWindow;
export { OrbitsWindow };

declare global {
  interface Window {
    config: OrbitsConfig;
  }
}

export type LoginRegisterClassesType = ClassNameMap<
  | "link"
  | "text"
  | "root"
  | "error"
  | "box"
  | "logo"
  | "field"
  | "errorField"
  | "btn"
  | "divider"
  | "bigTxt"
  | "bigTxt2"
  | "helperText"
>;
