import React, { useState } from "react";

import { Button, TextField, Typography } from "../styledComponents";
import ErrorBoundary from "./ErrorBoundary";
import {
  CANCEL_BUTTON_LABEL,
  en,
  FORGOT_PASSWORD_GUIDE_TEXT,
  RECOVER_YOUR_PASSWORD_HEADING,
  SEND_RECOVERY_EMAIL_BUTTON_LABEL,
  STEP_1_OF_2_LABEL,
  USERNAME_OR_EMAIL_FIELD_LABEL,
} from "../i18n";
import { validateUsername } from "../utils";
import { LoginRegisterClassesType } from "../types";
import { useStyles } from "../LoginRegister";

export type ForgotPasswordProps = {
  sendRecoveryEmail: (usernameOrEmail: string) => void;
  cancel: () => void;
};

export default function ForgotPassword(props: ForgotPasswordProps) {
  const classes: LoginRegisterClassesType = useStyles();
  const { sendRecoveryEmail, cancel } = props;
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const validateAndSetUsername = (value: string) => {
    setUsernameError(validateUsername(value, true));
    setUsername(value);
  };

  const _sendRecoveryEmail = () => {
    validateAndSetUsername(username);
    sendRecoveryEmail(username);
  };

  return (
    <ErrorBoundary>
      <Typography color="primary" variant="h3">
        {en[RECOVER_YOUR_PASSWORD_HEADING]}
      </Typography>
      <Typography color="primary" variant="h4">
        {en[STEP_1_OF_2_LABEL]}
      </Typography>
      <Typography color="primary">{en[FORGOT_PASSWORD_GUIDE_TEXT]}</Typography>
      <TextField
        autoFocus
        className={usernameError ? classes.errorField : classes.field}
        fullWidth
        label={en[USERNAME_OR_EMAIL_FIELD_LABEL]}
        value={username}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        ) => validateAndSetUsername(e.target.value)}
        error={Boolean(usernameError)}
        helperText={usernameError}
        autoComplete="username"
      />
      <div style={{ float: "right" }}>
        <Button color="primary" variant="outlined" onClick={cancel}>
          {en[CANCEL_BUTTON_LABEL]}
        </Button>
        <Button
          sx={{ ml: 1 }}
          color="primary"
          variant="contained"
          onClick={_sendRecoveryEmail}
        >
          {en[SEND_RECOVERY_EMAIL_BUTTON_LABEL]}
        </Button>
      </div>
    </ErrorBoundary>
  );
}
