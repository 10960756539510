import { Box, Menu, MenuItem, Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import React from "react";

import {
  en,
  NORMAL_PLAYBACK_SPEED_MENU_ITEM,
  PLAYBACK_SPEED_MENU_ITEM,
} from "../i18n";
import ErrorBoundary from "./ErrorBoundary";
import { colors } from "../themes";

const playbackRates = [
  { label: "0.25", value: 0.25 },
  { label: "0.5", value: 0.5 },
  { label: "0.75", value: 0.75 },
  { label: en[NORMAL_PLAYBACK_SPEED_MENU_ITEM], value: 1.0 },
  { label: "1.25", value: 1.25 },
  { label: "1.5", value: 1.5 },
  { label: "1.75", value: 1.75 },
  { label: "2", value: 2.0 },
];

export default function (props: {
  updatePlaybackRate: (rate: number) => void;
  selectedPlaybackRate: number;
}) {
  const { updatePlaybackRate, selectedPlaybackRate } = props;
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>(
    undefined
  );

  return (
    <ErrorBoundary>
      <Button
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          float: "right",
          fontSize: "16px",
          borderColor: colors.grey,
          borderRadius: "8px",
          borderTopLeftRadius: "0px 0px",
          borderTopRightRadius: "0px 0px",
          borderTop: "none",
          marginTop: "-5px",
          pl: 2,
          fontWeight: "bold",
          backgroundColor: colors.lightestGrey,
        }}
        color="secondary"
        variant="outlined"
        endIcon={<KeyboardArrowDownOutlinedIcon />}
      >
        {`${selectedPlaybackRate}x`}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(undefined)}
      >
        <MenuItem onClick={() => setAnchorEl(undefined)}>
          <span
            style={{
              fontWeight: "bold",
              paddingLeft: "42px",
              paddingRight: "24px",
            }}
          >
            {en[PLAYBACK_SPEED_MENU_ITEM]}
          </span>
        </MenuItem>
        {playbackRates.map(({ label, value }) => (
          <MenuItem
            key={label}
            onClick={() => {
              updatePlaybackRate(value);
              setAnchorEl(undefined);
            }}
            style={{
              paddingLeft: selectedPlaybackRate === value ? "16px" : "42px",
            }}
          >
            {selectedPlaybackRate === value && <CheckIcon color="secondary" />}
            <Box style={{ paddingLeft: "16px" }}>{label}</Box>
          </MenuItem>
        ))}
      </Menu>
    </ErrorBoundary>
  );
}
