import React, { useEffect, useState, useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Highlighter from "react-highlight-words";
import {
  Typography,
  InputAdornment,
  TextField,
  Button,
  LinearProgress,
  Box,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

import { AppContext } from "../AppContext";
import LoadingAnimation from "./LoadingAnimation";
import { OrbitsAppContext, GetVideoTranscriptResponse } from "../types";
import ErrorBoundary from "./ErrorBoundary";
import {
  en,
  REFRESH_BUTTON_LABEL,
  SEARCH_TRANSCRIPT_FIELD_LABEL,
  TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT,
  TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT_REFRESH_BOLDED,
} from "../i18n";
import { colors } from "../themes";

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      paddingTop: "48px",
      height: "150px",
      width: "150px",
      margin: "auto",
    },
    btn: {
      marginTop: "16px",
      borderRadius: "8px",
      marginLeft: "calc(50% - 50px)",
    },
    bold: {
      fontWeight: "bold",
    },
  })
);

const formatStartTime = (startTime: number) => {
  const lowerBoundStartTime = Math.floor(startTime);
  const hours = Math.floor(lowerBoundStartTime / 3600);
  const minutes = Math.floor((lowerBoundStartTime % 3600) / 60);
  const seconds = Math.floor(lowerBoundStartTime % 60);
  return `${hours > 9 ? hours : "0" + hours}:${
    minutes > 9 ? minutes : "0" + minutes
  }:${seconds >= 10 ? seconds : "0" + seconds}`;
};

function Transcript({ videoId }: { videoId: string }) {
  const classes = useStyles();
  const {
    eventApi,
    seekVideo,
    // triggerTranscriptAndKeywordsRefetch,
    getTranscript,
    findVideoByIdInTree,
  } = useContext<OrbitsAppContext>(AppContext);
  const [search, setSearch] = useState("");
  const [transcripts, setTranscripts] = useState<GetVideoTranscriptResponse>(
    []
  );
  const [
    displayTranscripts,
    setDisplayTranscripts,
  ] = useState<GetVideoTranscriptResponse>([]);

  const _setSearch = (searchText: string) => {
    // src: https://www.codeproject.com/Questions/1098800/Jquery-regexp-syntex-error-nothing-to-repeat
    const search = searchText.replace(/[-/^$*+?.()|[\]{}\\]/g, "\\$&"); // $& means the whole matched string
    setSearch(search);
    searchText &&
      eventApi({
        detailType: "ORBITS Transcript Searched",
        detail: { searchText, videoId },
      });
  };

  useEffect(() => {
    if (videoId) {
      const request = getTranscript(videoId);

      (async () => {
        const transcript = await request.fetch();
        // console.log("transcript", transcript);
        if (transcript && Array.isArray(transcript)) {
          setTranscripts(transcript);
        } else {
          setTranscripts([]);
          // console.log("Invalid transcript transcript: ", transcript);
        }
      })();
      return () => request.cancel();
    }
    return () => {};
  }, [videoId]);

  useEffect(() => {
    (async () => {
      try {
        if (!search) {
          setDisplayTranscripts(transcripts);
        } else {
          const searchRe = new RegExp(search, "i");

          const searchResults =
            transcripts?.filter(({ sentence }: { sentence: string }) =>
              searchRe.test(sentence)
            ) || [];

          if (searchResults) {
            setDisplayTranscripts(searchResults);
          } else {
            console.log("Invalid transcript searchResults: ", searchResults);
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [search, transcripts]);

  const inLast60Minutes =
    videoId &&
    (Number(new Date()) -
      Number(new Date(findVideoByIdInTree(videoId)?.ctime || 0))) /
      (1000 * 60) <
      60;

  return (
    <ErrorBoundary>
      <Box sx={{ mx: 2 }}>
        <TextField
          fullWidth
          placeholder={en[SEARCH_TRANSCRIPT_FIELD_LABEL]}
          type="search"
          variant="outlined"
          onChange={(e) => _setSearch(e.target.value)}
          InputProps={{
            sx: { color: "black", height: "50px" },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="inherit" sx={{ color: "black", mr: 1 }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {displayTranscripts.length ? (
        <Box
          sx={{
            height: "calc(100vh - 666px)",
            minHeight: "300px",
            overflowY: "scroll",
            ml: 2,
            mr: 1,
            mt: 1,
          }}
        >
          {displayTranscripts.map(({ sentence, startTime }) => (
            <Box key={startTime}>
              <Typography
                variant="body1"
                onClick={() => seekVideo(startTime, false)}
                sx={{
                  width: "fit-content",
                  borderWidth: "0px",
                  mt: 1,
                  fontSize: "14px",
                  cursor: "pointer",
                  ":hover": { backgroundColor: colors.lightestGrey },
                }}
                color="secondary"
              >
                {formatStartTime(startTime)}
              </Typography>
              <Box sx={{ m: 0, p: 0 }}>
                <Highlighter
                  searchWords={[search]}
                  textToHighlight={sentence}
                />
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <>
          {inLast60Minutes ? (
            <>
              <div className={classes.box}>
                <LoadingAnimation height={150} width={150} />
              </div>
              <Typography color="secondary">
                {en[TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT]}
                <span className={classes.bold}>
                  {
                    en[
                      TRANSCRIPT_VIDEO_TRANSCRIPTION_IN_PROGRESS_STATE_TEXT_REFRESH_BOLDED
                    ]
                  }
                </span>
              </Typography>
              <Button
                className={classes.btn}
                variant="outlined"
                color="secondary"
                // TODO: add back trigger by callback function
                // onClick={triggerTranscriptAndKeywordsRefetch}
              >
                {en[REFRESH_BUTTON_LABEL]}
              </Button>
            </>
          ) : (
            <LinearProgress />
          )}
        </>
      )}
    </ErrorBoundary>
  );
}

export default Transcript;
