import React from "react";

import orbitsPurpleLogoSVG from "../images/orbits-purple-logo.svg";
import { en, ORBOT_AVATAR_ALT } from "../i18n";

function OrbotAvatar() {
  return (
    <img
      height="auto"
      width="175px"
      alt={en[ORBOT_AVATAR_ALT]}
      src={orbitsPurpleLogoSVG}
    />
  );
}

export default OrbotAvatar;
