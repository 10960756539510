import React, { useState, useContext } from "react";

import { Button, Divider, TextField, Typography } from "../styledComponents";
import { AppContext } from "../AppContext";
import { validatePassword } from "../utils";
import ErrorBoundary from "./ErrorBoundary";
import {
  CANCEL_BUTTON_LABEL,
  CREATE_PASSWORD_BUTTON_LABEL,
  en,
  MISSING_PASSWORD_ERROR_MSG,
  NEW_PASSWORD_FILED_LABEL,
  NEW_PASSWORD_FILED_LABEL_2,
  NEW_PASSWORD_REQUIRED_HEADING,
  PASSWORDS_DONT_MATCH_ERROR_MSG,
  UNKOWN_EMAIL_PLACEHOLDER_TEXT,
} from "../i18n";
import { LoginRegisterClassesType, OrbitsAppContext } from "../types";
import { useStyles } from "../LoginRegister";

export type NewPasswordRequiredProps = {
  email: string | undefined;
  changeTempPassword: (newPassword: string) => Promise<void>;
};

export default function (props: NewPasswordRequiredProps) {
  const classes: LoginRegisterClassesType = useStyles();
  const { email, changeTempPassword } = props;
  const { logoutUser } = useContext<OrbitsAppContext>(AppContext);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const updateNewPassword = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    setNewPasswordError(validatePassword(value));
    setNewPassword(value);
  };

  const [newPassword2, setNewPassword2] = useState("");
  const [newPasswordError2, setNewPasswordError2] = useState("");
  const updateNewPassword2 = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    setNewPasswordError2("");
    if (!value) {
      setNewPasswordError2(en[MISSING_PASSWORD_ERROR_MSG]);
    } else if (newPassword !== value) {
      setNewPasswordError2(en[PASSWORDS_DONT_MATCH_ERROR_MSG]);
    }
    setNewPassword2(value);
  };

  const isNewPasswordValid =
    newPassword && !newPasswordError && !newPasswordError2;

  return (
    <ErrorBoundary>
      <Divider className={classes.divider} />
      <Typography color="primary">
        {en[NEW_PASSWORD_REQUIRED_HEADING]}
      </Typography>
      <Typography color="primary">
        {email || en[UNKOWN_EMAIL_PLACEHOLDER_TEXT]}
      </Typography>
      <TextField
        className={newPasswordError ? classes.errorField : classes.field}
        fullWidth
        label={en[NEW_PASSWORD_FILED_LABEL]}
        value={newPassword}
        type="password"
        onChange={updateNewPassword}
        error={Boolean(newPasswordError)}
        helperText={newPasswordError}
        autoComplete="new-password"
      />
      <TextField
        className={newPasswordError2 ? classes.errorField : classes.field}
        fullWidth
        label={en[NEW_PASSWORD_FILED_LABEL_2]}
        value={newPassword2}
        type="password"
        onChange={updateNewPassword2}
        error={Boolean(newPasswordError2)}
        helperText={newPasswordError2}
        autoComplete="new-password"
      />
      <Divider className={classes.divider} />
      <div style={{ float: "right" }}>
        <Button
          className={classes.btn}
          variant="contained"
          onClick={logoutUser}
        >
          {en[CANCEL_BUTTON_LABEL]}
        </Button>
        <Button
          sx={{ marginLeft: 1 }}
          className={classes.btn}
          color="primary"
          variant="contained"
          onClick={() => isNewPasswordValid && changeTempPassword(newPassword)}
        >
          {en[CREATE_PASSWORD_BUTTON_LABEL]}
        </Button>
      </div>
    </ErrorBoundary>
  );
}
