import * as React from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";

import { en, TRANSCRIPT_TITLE } from "../i18n";
import Transcript from "./Transcript";
import Video from "./Video";
import { AppContext } from "../AppContext";
import { OrbitsAppContext, ResourceType } from "../types";
import transcriptIcon from "../images/transcript.svg";
import PDF from "./PDF";

export default function ({ resourceId }: { resourceId: string }) {
  const { findVideoByIdInTreeAndUploads } = React.useContext<OrbitsAppContext>(
    AppContext
  );
  const selectedResource = resourceId
    ? findVideoByIdInTreeAndUploads(resourceId)
    : undefined;

  const type: ResourceType = selectedResource?.type || "video";
  // console.log("ResourceDetailSideBar", type, resourceId, selectedResource);

  const resourceScreenMap: Map<ResourceType, JSX.Element> = new Map([
    [
      "video",
      <>
        {resourceId && selectedResource && (
          <Grid item xs={12} md={4} sx={{ height: "calc(100vh - 90px)" }}>
            <Box sx={{ px: 2, pt: 1 }}>
              <Video videoId={resourceId} type={type} />
            </Box>
            <Box sx={{ px: 2 }}>
              {/*
            <Typography variant="body1" sx={{ mt: 1 }}>
              {`Recommendation: ${suggestedTopic}`}
            </Typography>
          */}
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {selectedResource?.title || "Loading video..."}
              </Typography>
              {/*
            <Typography variant="body1" sx={{ mt: 1 }}>
              { "Nam dui arcu, molestie quis semper sit amet, efficitur eu urna. Cras hendrerit nec justo at accumsan. Etiam at venenatis magna. Nulla a augue mi." }
            </Typography>
          */}
              <Divider sx={{ my: 1 }} />
              <Box sx={{ display: "flex", alignContent: "center", mb: 1 }}>
                <img src={transcriptIcon} />
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{ display: "inline", ml: 1 }}
                >
                  {en[TRANSCRIPT_TITLE]}
                </Typography>
              </Box>
            </Box>
            <Transcript videoId={resourceId} />
          </Grid>
        )}
      </>,
    ],
    [
      "audio",
      <>
        {resourceId && selectedResource && (
          <Grid item xs={12} md={4} sx={{ height: "calc(100vh - 50px)" }}>
            <Box sx={{ px: 2, pt: 1 }}>
              <Video videoId={resourceId} type={type} />
            </Box>
            <Box sx={{ px: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {selectedResource?.title || "Loading Audio..."}
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Box sx={{ display: "flex", alignContent: "center", mb: 1 }}>
                <img src={transcriptIcon} />
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{ display: "inline", ml: 1 }}
                >
                  {en[TRANSCRIPT_TITLE]}
                </Typography>
              </Box>
            </Box>
            <Transcript videoId={resourceId} />
          </Grid>
        )}
      </>,
    ],
    [
      "pdf",
      <>
        {resourceId && selectedResource && (
          <Grid item xs={12} md={4} sx={{ height: "calc(100vh - 150px)" }}>
            <PDF resourceId={resourceId} />
          </Grid>
        )}
      </>,
    ],
  ]);

  return resourceScreenMap.get(type) || <>{`invalid screen: ${type}`}</>;
}
