import React, { useState } from "react";

import { Button, TextField, Typography } from "../styledComponents";
import ErrorBoundary from "./ErrorBoundary";
import {
  validatePassword as validatePasswordUtil,
  validateEmail as validateEmailUtil,
  validateUsername as validateUsernameUtil,
} from "../utils";
import {
  CANCEL_BUTTON_LABEL,
  CREATE_ACCOUNT_BUTTON_LABEL,
  EMAIL_FIELD_LABEL,
  en,
  FULL_NAME_EXAMPLE_TEXT,
  FULL_NAME_FIELD_LABEL,
  MISSING_FULL_NAME_ERROR_MSG,
  PASSWORDS_DONT_MATCH_ERROR_MSG,
  PASSWORD_FIELD_LABEL,
  REGISTER_HEADING,
  RE_ENTER_PASSWORD_FIELD_LABEL,
  STEP_1_OF_2_LABEL,
  USERNAME_FIELD_LABEL,
} from "../i18n";
import { LoginRegisterClassesType } from "../types";
import { useStyles } from "../LoginRegister";

export type RegisterPropsType = {
  createAccountSubmitInfo: (
    fullName: string,
    email: string,
    username: string,
    password: string
  ) => Promise<void>;
  cancel: () => void;
};

export default function Register(props: RegisterPropsType) {
  const classes: LoginRegisterClassesType = useStyles();
  const { createAccountSubmitInfo, cancel } = props;

  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password2, setPassword2] = useState("");
  const [password2Error, setPassword2Error] = useState("");

  const validateFullName = (value: string) => {
    setFullNameError("");
    if (!value) {
      setFullNameError(en[MISSING_FULL_NAME_ERROR_MSG]);
    }
  };
  const _setFullName = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    setFullName(value);
    if (fullNameError) {
      validateFullName(value);
    }
  };

  const validateEmail = (value: string) =>
    setEmailError(validateEmailUtil(value));

  const _setEmail = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    setEmail(value);
    if (emailError) {
      validateEmail(value);
    }
  };

  const validateUsername = (value: string) =>
    setUsernameError(validateUsernameUtil(value, false));

  const _setUsername = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    setUsername(value);
    if (usernameError) {
      validateUsername(value);
    }
  };

  const validatePassword = (value: string) =>
    setPasswordError(validatePasswordUtil(value));

  const _setPassword = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    setPassword(value);
    if (passwordError) {
      validatePassword(value);
    }
  };

  const validatePassword2 = (value: string) => {
    setPassword2Error("");
    if (value !== password) {
      setPassword2Error(en[PASSWORDS_DONT_MATCH_ERROR_MSG]);
    }
  };
  const _setPassword2 = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    setPassword2(value);
    if (password2Error) {
      validatePassword2(value);
    }
  };

  const _createAccountSubmitInfo = () => {
    validateFullName(fullName);
    validateEmail(email);
    validateUsername(username);
    validatePassword(password);
    validatePassword2(password2);

    if (
      fullNameError ||
      emailError ||
      usernameError ||
      passwordError ||
      password2Error
    ) {
      console.log({
        fullNameErrorBool: !!fullNameError,
        emailErrorBool: !!emailError,
        usernameErrorBool: !!usernameError,
        passwordErrorBool: !!passwordError,
        password2ErrorBool: !!password2Error,
      });
      return;
    }
    createAccountSubmitInfo(fullName, email, username, password);
  };

  return (
    <ErrorBoundary>
      <Typography color="primary" variant="h3">
        {en[REGISTER_HEADING]}
      </Typography>
      <Typography color="primary" variant="h4">
        {en[STEP_1_OF_2_LABEL]}
      </Typography>
      <TextField
        autoFocus
        className={fullNameError ? classes.errorField : classes.field}
        fullWidth
        label={en[FULL_NAME_FIELD_LABEL]}
        value={fullName}
        onChange={_setFullName}
        onBlur={() => validateFullName(fullName)}
        error={Boolean(fullNameError)}
        helperText={fullNameError}
        autoComplete="name"
      />
      <Typography className={classes.helperText}>
        {en[FULL_NAME_EXAMPLE_TEXT]}
      </Typography>
      <TextField
        className={emailError ? classes.errorField : classes.field}
        fullWidth
        label={en[EMAIL_FIELD_LABEL]}
        value={email}
        onChange={_setEmail}
        onBlur={() => validateEmail(email)}
        error={Boolean(emailError)}
        helperText={emailError}
        autoComplete="email"
      />
      <TextField
        className={usernameError ? classes.errorField : classes.field}
        fullWidth
        label={en[USERNAME_FIELD_LABEL]}
        value={username}
        onChange={_setUsername}
        onBlur={() => validateUsername(username)}
        error={Boolean(usernameError)}
        helperText={usernameError}
        autoComplete="username"
      />
      <TextField
        className={passwordError ? classes.errorField : classes.field}
        fullWidth
        label={en[PASSWORD_FIELD_LABEL]}
        type="password"
        value={password}
        onChange={_setPassword}
        onBlur={() => validatePassword(password)}
        error={Boolean(passwordError)}
        helperText={passwordError}
        autoComplete="new-password"
      />
      <TextField
        className={password2Error ? classes.errorField : classes.field}
        fullWidth
        label={en[RE_ENTER_PASSWORD_FIELD_LABEL]}
        type="password"
        value={password2}
        onChange={_setPassword2}
        onBlur={() => validatePassword2(password2)}
        onKeyDown={(e) => e.key === "Enter" && _createAccountSubmitInfo()}
        error={Boolean(password2Error)}
        helperText={password2Error}
        autoComplete="new-password"
      />
      <div style={{ float: "right" }}>
        <Button
          className={classes.btn}
          color="primary"
          variant="outlined"
          onClick={cancel}
        >
          {en[CANCEL_BUTTON_LABEL]}
        </Button>
        <Button
          sx={{ marginLeft: 1 }}
          className={classes.btn}
          color="primary"
          variant="contained"
          onClick={_createAccountSubmitInfo}
        >
          {en[CREATE_ACCOUNT_BUTTON_LABEL]}
        </Button>
      </div>
    </ErrorBoundary>
  );
}
