import { getCLS, getFID, getLCP, ReportHandler } from "web-vitals";

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    if (
      PerformanceObserver.supportedEntryTypes.includes(
        "largest-contentful-paint"
      )
    ) {
      getLCP(onPerfEntry);
      getFID(onPerfEntry);
      getCLS(onPerfEntry);
    } else {
      onPerfEntry({
        name: "LCP",
        value: 0,
        delta: 0,
        isFinal: true,
        id: "",
        entries: performance.getEntries(),
      });
    }
  }
};

export default reportWebVitals;
