import { useState, useContext } from "react";
import { GridColumns, GridSelectionModel } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";

import {
  DELETE_BUTTON_LABEL,
  en,
  PREVIEW_COLUMN_LABEL,
  RESOURCE_COLUMN_LABEL,
  SHARED_WITH_COLUMN_LABEL,
  VIDEO_DELETE_CONFIRMATION_MSG,
  VIDEO_DELETE_FAILURE_MSG,
  VIDEO_DELETE_SUCCESS_MSG,
  VIDEO_DELETE_TABLE_ARIA_LABEL,
  VIDEO_DELETE_TITLE_TEXT,
} from "../i18n";
import UploadVideoButton from "./UploadVideoButton";
import { AppContext } from "../AppContext";
import { Resource, OrbitsAppContext } from "../types";
import VideoThumbnail from "./VideoThumbnail";
import DataGrid from "./DataGrid";
import BaseModal from "./BaseModal";
import { myContentBasePath } from "../utils";
import ResourceDetailSidebar from "./ResourceDetailSidebar";

export default function () {
  const history = useHistory();
  const {
    uploads,
    setSelectedResource,
    fetchUploads,
    openSnackbar,
    deleteVideo,
    findVideoByIdInTreeAndUploads,
    setSelectedGroup,
    fetchSharedWithForResourceId,
    removeResources,
  } = useContext<OrbitsAppContext>(AppContext);
  const [selectedResourceIds, setSelectedResourceIds] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  if (!uploads) {
    fetchUploads();
  }

  const deleteBtnClicked = async () => {
    setIsModalOpen(false);
    try {
      const deleteVideoPromises = selectedResourceIds.map(deleteVideo);
      const response = await Promise.all(deleteVideoPromises);
      console.log("deleteVideoPromises response:", response);
      openSnackbar(
        "success",
        en[VIDEO_DELETE_SUCCESS_MSG](selectedResourceIds.length)
      );
    } catch (error) {
      console.log("Error:", error);
      openSnackbar(
        "error",
        en[VIDEO_DELETE_FAILURE_MSG](selectedResourceIds.length)
      );
    } finally {
      removeResources(selectedResourceIds);
      setTimeout(fetchUploads, 1_000);
    }
  };

  const columns: GridColumns<Resource> = [
    {
      field: "Preview",
      headerName: en[PREVIEW_COLUMN_LABEL].toString(),
      flex: 2,
      sortable: false,
      renderCell: (params) => (
        <VideoThumbnail videoId={params.id + ""} type={params.row.type} />
      ),
    },
    {
      field: "title",
      headerName: en[RESOURCE_COLUMN_LABEL].toString(),
      flex: 4,
      renderCell: (params) => (
        <Typography variant="body1" color="black" sx={{ display: "inline" }}>
          {params.row.title}
        </Typography>
      ),
    },
    {
      field: "shared-with",
      headerName: en[SHARED_WITH_COLUMN_LABEL].toString(),
      flex: 8,
      sortable: false,
      renderCell: (params) => {
        const sharedWith = params.row.sharedWith;
        if (
          sharedWith === undefined &&
          params.row.uploadPercent === undefined
        ) {
          fetchSharedWithForResourceId(params.id + "");
        }
        return (
          <Typography variant="body1" sx={{ display: "inline" }}>
            {sharedWith?.map((group) => (
              <span
                key={group.id}
                onClick={() => {
                  history.push(myContentBasePath);
                  setSelectedGroup(group);
                  setTimeout(() => setSelectedResource(params.id + ""), 0);
                }}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  marginRight: "5px",
                }}
              >
                {group.displayName}
              </span>
            ))}
          </Typography>
        );
      },
    },
  ];

  const DeleteVideosButton = ({ hide }: { hide: boolean }) => (
    <Button
      id="my-uploads-delete-btn"
      sx={{ display: hide ? "none" : "default" }}
      onClick={handleOpen}
      color="secondary"
      variant="contained"
      startIcon={<DeleteIcon />}
    >
      {en[DELETE_BUTTON_LABEL]}
    </Button>
  );

  const createModalRow = (videoId: string) => {
    return (
      <TableRow key={videoId}>
        <TableCell>
          <VideoThumbnail
            videoId={videoId}
            type={findVideoByIdInTreeAndUploads(videoId)?.type || "video"}
          />
        </TableCell>
        <TableCell>{findVideoByIdInTreeAndUploads(videoId)?.title}</TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box m={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "align-items",
          }}
        >
          <Typography variant="h4" sx={{ display: "inline" }}>
            Content Library
          </Typography>
          <UploadVideoButton hide={selectedResourceIds.length !== 0} />
          <DeleteVideosButton hide={selectedResourceIds.length === 0} />
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={selectedResourceIds.length === 1 ? 8 : 12}>
            <DataGrid
              contentHightAbove={160}
              rows={uploads || []}
              columns={columns}
              checkboxSelection
              disableColumnMenu
              disableSelectionOnClick
              disableSelectedRowBorder
              selectedResourceIds={selectedResourceIds}
              onSelectionModelChange={(selection: GridSelectionModel) =>
                setSelectedResourceIds(selection.map((id) => id + ""))
              }
            />
          </Grid>
          <ResourceDetailSidebar
            resourceId={
              selectedResourceIds.length === 1 ? selectedResourceIds[0] : ""
            }
          />
        </Grid>
      </Box>
      <BaseModal
        heading={en[VIDEO_DELETE_TITLE_TEXT](selectedResourceIds.length)}
        id="my-uploads-delete-modal"
        isModalOpen={isModalOpen}
        close={handleClose}
        onPrimaryButtonClick={deleteBtnClicked}
        primaryButtonLabel={en[DELETE_BUTTON_LABEL]}
      >
        <Typography sx={{ mt: 2 }}>
          {en[VIDEO_DELETE_CONFIRMATION_MSG](selectedResourceIds.length)}
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ mt: 2, overflowY: "scroll", maxHeight: "42vh" }}
        >
          <Table size="small" aria-label={en[VIDEO_DELETE_TABLE_ARIA_LABEL]}>
            <TableBody>{selectedResourceIds.map(createModalRow)}</TableBody>
          </Table>
        </TableContainer>
      </BaseModal>
    </>
  );
}
