import { Breadcrumbs, Button, Menu, MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { GridColumns, GridSelectionModel } from "@mui/x-data-grid";
import ArrowForwardIosOutlined from "@mui/icons-material/ArrowForwardIosOutlined";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Home from "@mui/icons-material/Home";
import * as React from "react";
import ago from "s-ago";

import { AppContext } from "./AppContext";
import { Group, OrbitsAppContext, Resource, Tree, defaultState } from "./types";
import VideoThumbnail from "./components/VideoThumbnail";
import { en, PREVIEW_COLUMN_LABEL } from "./i18n";
import trianglePurpleSVG from "./images/triangle-purple.svg";
import { colors } from "./themes";
import DataGrid from "./components/DataGrid";
// import Tabs from "./components/Tabs";
import UploadVideoButton from "./components/UploadVideoButton";
import ResourceDetailSidebar from "./components/ResourceDetailSidebar";
// import Quiz from "./components/Quiz";
// import { SmartQuizIcon, StudyPathIcon } from "./svgIcons";
// import ContentSearchBar from "./components/ContentSearchBar";
// import SubjectScoreChart from "./components/SubjectScoreChart";

export default function () {
  const {
    fetchGroupShares,
    fetchSubgroups,
    setSelectedGroup,
    setSelectedResource,
    selectedResourceId,
    sharableGroups,
    selectedGroupId,
    tree,
    breadcrumbs,
    fetchTree,
  } = React.useContext<OrbitsAppContext>(AppContext);

  const selectedGroup = Tree.findGroupById(tree, selectedGroupId);

  React.useEffect(() => {
    if (selectedGroupId === defaultState.tree.id && !tree.childGroups) {
      fetchTree();
    } else {
      fetchSubgroups(selectedGroupId);
      fetchGroupShares(selectedGroupId);
    }
  }, [selectedGroupId]);
  const [isSubgroupMenuOpen, setIsSubgroupMenuOpen] = React.useState<boolean>(
    false
  );

  const columns: GridColumns<Resource> = [
    {
      field: "Preview",
      headerName: en[PREVIEW_COLUMN_LABEL].toString(),
      flex: 2,
      sortable: false,
      renderCell: (params) => (
        <VideoThumbnail videoId={params.id + ""} type={params.row.type} />
      ),
      filterable: false,
    },
    {
      field: "creator",
      valueGetter: (params) => params.row.creator,
      headerName: "Created by",
      flex: 4,
      renderCell: (params) => (
        <Typography variant="body1" color="black">
          {params.row.creator}
        </Typography>
      ),
    },
    {
      field: "ctime",
      headerName: "Creation time",
      flex: 4,
      renderCell: (params) => {
        const [weekday, month, day, year] = new Date(params.row.ctime)
          .toDateString()
          .split(" ");
        const formattedDate = `${weekday} - ${month} ${day}, ${year}`;
        const agoTime = ago(new Date(params.row.ctime));
        return (
          <Typography
            variant="body1"
            color="black"
          >{`${formattedDate} (${agoTime})`}</Typography>
        );
      },
    },
    {
      valueGetter: (params) => params.row.title,
      field: "title",
      headerName: "Resource title",
      flex: 4,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex" }}>
          <Typography variant="body1" color="black" sx={{ display: "inline" }}>
            {params.row.title}
          </Typography>
          {params.id === selectedResourceId && (
            <img
              src={trianglePurpleSVG}
              style={{ position: "absolute", right: "8px" }}
            />
          )}
        </Box>
      ),
    },
  ];

  // console.log("Subject.tsx: breadcrumbs", breadcrumbs);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={selectedResourceId ? 8 : 12}>
        <Box
          pl={2}
          pt={1}
          pr={0}
          pb={0}
          sx={{
            borderRight: `4px ${
              selectedResourceId ? colors.primary : colors.white
            } solid`,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Breadcrumbs separator={<ArrowForwardIosOutlined />}>
              {breadcrumbs.map((breadcrumb, idx) => (
                <Button
                  key={breadcrumb.id}
                  size="medium"
                  color="secondary"
                  variant="outlined"
                  sx={{
                    backgroundColor: colors.lightGrey,
                    borderRadius: "16px",
                  }}
                  startIcon={idx === 0 ? <Home color="secondary" /> : null}
                  disabled={breadcrumb.id === selectedGroupId}
                >
                  <Typography
                    color="secondary"
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight:
                        breadcrumb.id === selectedGroupId ? "bold" : "normal",
                    }}
                    onClick={() => setSelectedGroup(breadcrumb)}
                  >
                    {breadcrumb.displayName}
                  </Typography>
                </Button>
              ))}
            </Breadcrumbs>
            {Array.isArray(selectedGroup?.childGroups) &&
              selectedGroup?.childGroups.length !== 0 && (
                <>
                  <Button
                    id="subgroup-menu-icon"
                    size="medium"
                    color="secondary"
                    variant="outlined"
                    sx={{
                      backgroundColor: colors.lightGrey,
                      borderRadius: "16px",
                      ml: 2,
                    }}
                    endIcon={<ExpandMore color="secondary" />}
                    onClick={() => setIsSubgroupMenuOpen(!isSubgroupMenuOpen)}
                  >
                    <Typography
                      color="secondary"
                      variant="body1"
                      sx={{ fontSize: "16px" }}
                    >
                      more
                    </Typography>
                  </Button>
                  <Menu
                    open={isSubgroupMenuOpen}
                    onClose={() => setIsSubgroupMenuOpen(false)}
                    anchorEl={document.getElementById("subgroup-menu-icon")}
                    sx={{ display: isSubgroupMenuOpen ? "block" : "none" }}
                  >
                    {selectedGroup?.childGroups?.map((subgroup) => (
                      <MenuItem
                        key={subgroup.id}
                        onClick={() => {
                          setSelectedGroup(subgroup);
                          setIsSubgroupMenuOpen(false);
                        }}
                      >
                        <Typography variant="body1" color="secondary">
                          {subgroup.displayName}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
          </Box>
          {/* <Box sx={{ display: "flex", justifyContent: "space-between", my: 2 }}> */}
          {/* <Typography
              variant="h5"
              sx={{ display: "inline" }}
            >{`Subject: ${selectedBreadcrumb.displayName}`}</Typography> */}
          <Box sx={{ m: 2, float: "right" }}>
            {/* <Button variant="outlined" color="primary" sx={{ mr: 1 }}>
                View Study Space
              </Button> */}
            <UploadVideoButton
              hide={
                sharableGroups
                  ? sharableGroups.findIndex(
                      (g: Group) => g.id === selectedGroupId
                    ) === -1
                  : true
              }
              preSelectedGroup={selectedGroup}
            />
          </Box>
          {/* </Box> */}
          {/* <Box mr={2} mt={2}>
            <SubjectScoreChart />
          </Box> */}
          <Box m={2} mb={0} ml={0}>
            {/* <ContentSearchBar subjectName={selectedBreadcrumb.displayName} /> */}
            <Typography variant="h5" sx={{ display: "inline" }}>
              Content
            </Typography>
          </Box>
          <DataGrid
            contentHightAbove={240}
            rows={selectedGroup?.videos ?? []}
            columns={columns}
            // selectedResourceIds={[selectedResourceId]}
            onSelectionModelChange={(selection: GridSelectionModel) =>
              selection.length && setSelectedResource(selection[0].toString())
            }
          />
        </Box>
      </Grid>
      <ResourceDetailSidebar resourceId={selectedResourceId} />
      {/* ) : (
          <Tabs
            elements={[
              {
                label: "Smart Quiz",
                icon: SmartQuizIcon,
                content: <Quiz />,
              },
              {
                label: "Study Path",
                icon: StudyPathIcon,
                content: (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80%",
                    }}
                  >
                    <Typography variant="h3" color="secondary">
                      Study Path
                    </Typography>
                  </Box>
                ),
              },
            ]}
          />
        )} */}
    </Grid>
  );
}
