import React, { useContext, useState } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Auth } from "aws-amplify";
import Alert from "@mui/lab/Alert";
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  // useRouteMatch,
  useHistory,
  // useParams
} from "react-router-dom";
import {
  CognitoUser,
  ICognitoUserAttributeData,
} from "amazon-cognito-identity-js";
import { promisify } from "util";

import orbitsPurpleLogoSVG from "./images/orbits-purple-logo.svg";
import { AppContext } from "./AppContext";
import Login from "./components/Login";
import NewPasswordRequired from "./components/NewPasswordRequired";
import ForgotPassword from "./components/ForgotPassword";
import ForgotPasswordSubmit from "./components/ForgotPasswordSubmit";
import Register from "./components/Register";
import RegisterConfirmation from "./components/RegisterConfirmation";
import {
  errorFieldBackgroundColor,
  errorFieldColor,
  fieldBackgroundColor,
  fieldColor,
} from "./themes";
import { authBasePath, getAuthErrorMessage } from "./utils";
import {
  en,
  LOGIN_ROUTE_TEXT,
  NEW_PASSWORD_REQUIRED_ROUTE_TEXT,
  FORGOT_PASSWORD_ROUTE_TEXT,
  FORGOT_PASSWORD_SUBMIT_ROUTE_TEXT,
  REGISTER_ROUTE_TEXT,
  REGISTER_CONFIRMATION_ROUTE_TEXT,
  // MYCONTENT_BASE_ROUTE_TEXT,
} from "./i18n";
import {
  Action,
  AppState,
  // AuthState,
  LoginRegisterClassesType,
  OrbitsAppContext,
} from "./types";

export const useStyles = makeStyles({
  root: {
    padding: "32px",
    paddingTop: "16vh",
  },
  box: {
    width: "560px",
    margin: "auto",
  },
  logo: {
    width: "100%",
    height: "auto",
    paddingBottom: "16px",
  },
  field: {
    color: fieldColor,
    backgroundColor: fieldBackgroundColor,
  },
  errorField: {
    "& .Mui-error": {
      color: errorFieldColor,
    },
    "& .MuiFormHelperText-root": {
      color: errorFieldColor,
    },
    backgroundColor: errorFieldBackgroundColor,
  },
  btn: {
    borderRadius: "8px",
  },
  error: {
    margin: "30px 0 30px 0",
  },
  divider: {
    width: "container",
    margin: "30px 0 30px 0",
  },
  bigTxt: {
    fontSize: "32px",
    display: "inline",
    fontWeight: 400,
    lineHeight: "40px",
  },
  bigTxt2: {
    fontSize: "24px",
    lineHeight: "40px",
  },
  text: {
    marginTop: "15px",
    fontSize: "16px",
    lineHeight: "24px",
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  helperText: {
    marginLeft: "16px",
  },
});

export default function () {
  const classes: LoginRegisterClassesType = useStyles();

  const {
    dispatch,
    // authState,
    cognitioUser,
    logoutUser,
    loginUser,
  } = useContext<OrbitsAppContext>(AppContext);

  const [error, setError] = useState<JSX.Element | undefined>(undefined);
  const [authHeading, setAuthHeading] = useState("");
  const [username, setUsername] = useState("");
  const [tempCognitioSession, setTempCognitioSession] = useState<
    CognitoUser | undefined
  >(undefined);
  const [isPasswordResetRequired, setIsPasswordResetRequired] = useState(false);

  const history = useHistory();
  const email = cognitioUser?.attributes?.["email"] || "";

  const dispatchWithRouteUpdate = (action: Action) => {
    dispatch(action);
    const key = action.type + "_ROUTE_TEXT";

    let pathPostfix = en[LOGIN_ROUTE_TEXT];

    if (key === NEW_PASSWORD_REQUIRED_ROUTE_TEXT) {
      pathPostfix = en[NEW_PASSWORD_REQUIRED_ROUTE_TEXT];
    } else if (key === FORGOT_PASSWORD_ROUTE_TEXT) {
      pathPostfix = en[FORGOT_PASSWORD_ROUTE_TEXT];
    } else if (key === FORGOT_PASSWORD_SUBMIT_ROUTE_TEXT) {
      pathPostfix = en[FORGOT_PASSWORD_SUBMIT_ROUTE_TEXT];
    } else if (key === REGISTER_ROUTE_TEXT) {
      pathPostfix = en[REGISTER_ROUTE_TEXT];
    } else if (key === REGISTER_CONFIRMATION_ROUTE_TEXT) {
      pathPostfix = en[REGISTER_CONFIRMATION_ROUTE_TEXT];
    }

    history.push(`${authBasePath}/${pathPostfix}`);
  };

  const resetErrorAndHeading = () => {
    setError(undefined);
    setAuthHeading("");
  };

  const login = async (username: string, password: string) => {
    try {
      resetErrorAndHeading();
      const user: CognitoUser | undefined = await Auth.signIn(
        username.trim(),
        password,
        { signin: "true" }
      );

      if (!user) {
        setAuthHeading("Login failed");
        return;
      }
      setTempCognitioSession(user);

      const userChallangeName = (user as CognitoUser & {
        challengeName?: string;
      }).challengeName;
      // console.log("userChallangeName:", userChallangeName);

      if (userChallangeName) {
        if (userChallangeName === "NEW_PASSWORD_REQUIRED") {
          dispatchWithRouteUpdate({ type: "NEW_PASSWORD_REQUIRED" });
          return;
        } else {
          throw new Error(`Unexpected challenge name: ${userChallangeName}`);
        }
      }

      const attributes = ((await promisify(user.getUserAttributes).bind(
        user
      )()) as ICognitoUserAttributeData[]).reduce(
        (acc, curr: ICognitoUserAttributeData) => {
          acc[curr.Name] = curr.Value;
          return acc;
        },
        {} as { [key: string]: string }
      );

      const payload: AppState["cognitioUser"] = {
        username: user.getUsername(),
        attributes,
        idToken: user.getSignInUserSession()?.getIdToken().getJwtToken() || "",
        refreshToken:
          user.getSignInUserSession()?.getRefreshToken().getToken() || "",
      };

      loginUser(payload);
    } catch (e) {
      logoutUser();
      setError(getAuthErrorMessage(e));
      if (
        e &&
        typeof e === "object" &&
        "code" in e &&
        typeof (e as { code: string }).code === "string"
      ) {
        const code = (e as { code: string }).code;
        if (code === "UserNotConfirmedException") {
          setUsername(username);
          dispatchWithRouteUpdate({ type: "REGISTER_CONFIRMATION" });
        }
        if (code === "PasswordResetRequiredException") {
          sendRecoveryEmail(username);
          setIsPasswordResetRequired(true);
        }
      } else {
        console.error("login error:", e);
      }
    }
  };

  const createAccountSubmitInfo = async (
    name: string,
    email: string,
    username: string,
    password: string
  ): Promise<void> => {
    try {
      resetErrorAndHeading();
      setUsername(username);
      const signUpResponse = await Auth.signUp({
        username: username.trim(),
        password,
        attributes: {
          name: name.trim(),
          email: email.trim(),
        },
      });
      console.log({ signUpResponse });
      dispatchWithRouteUpdate({ type: "REGISTER_CONFIRMATION" });
    } catch (e) {
      setError(getAuthErrorMessage(e));
    }
  };

  const createAccountSubmitOTP = async (code: string) => {
    try {
      resetErrorAndHeading();
      const confirmSignUpResponse = await Auth.confirmSignUp(
        username.trim(),
        code.trim(),
        { forceAliasCreation: true }
      );
      console.log({ confirmSignUpResponse });
      _logoutUser();
    } catch (e) {
      setError(getAuthErrorMessage(e, "createAccountSubmitOTP"));
    }
  };

  const changeTempPassword = async (newPassword: string) => {
    console.log("changeTempPassword", tempCognitioSession, newPassword);
    try {
      resetErrorAndHeading();
      const user = await Auth.completeNewPassword(
        tempCognitioSession,
        newPassword
      ); // XUSHPd@Q6DS5K2
      if (!user) {
        setError(<>Something unexpected happened</>);
      } else {
        setAuthHeading("You created a new password");
      }
      dispatchWithRouteUpdate({ type: "LOGOUT" });
    } catch (e) {
      logoutUser();
      setError(getAuthErrorMessage(e));
    }
  };

  const gotoForgetPassword = () => {
    resetErrorAndHeading();
    dispatchWithRouteUpdate({ type: "FORGOT_PASSWORD" });
  };

  const gotoRegister = () => {
    resetErrorAndHeading();
    dispatchWithRouteUpdate({ type: "REGISTER" });
  };

  const sendRecoveryEmail = async (username: string) => {
    try {
      resetErrorAndHeading();
      const response = await Auth.forgotPassword(username.trim(), {
        forgotPassword: "true",
      });
      console.log(response);

      setUsername(username);
      dispatchWithRouteUpdate({ type: "FORGOT_PASSWORD_SUBMIT" });
    } catch (e) {
      setError(getAuthErrorMessage(e));
    }
  };

  const changeForgottenPassword = async (code: string, newPassword: string) => {
    try {
      resetErrorAndHeading();
      const reponse = await Auth.forgotPasswordSubmit(
        username.trim(),
        code.trim(),
        newPassword
      );
      console.log(reponse);

      dispatchWithRouteUpdate({ type: "LOGOUT" });
    } catch (e) {
      setError(getAuthErrorMessage(e));
    }
  };

  const _logoutUser = () => {
    resetErrorAndHeading();
    logoutUser();
    history.push(`${authBasePath}/${en[LOGIN_ROUTE_TEXT]}`);
  };

  // Logout user if state is REGISTER_CONFIRMATION without an username
  // OR state is FORGOT_PASSWORD_SUBMIT without an username
  // useEffect(() => {
  //   if (
  //     (authState === AuthState.REGISTER_CONFIRMATION ||
  //       authState === AuthState.FORGOT_PASSWORD_SUBMIT ||
  //       authState === AuthState.NEW_PASSWORD_REQUIRED) &&
  //     !username
  //   )
  //     _logoutUser();
  // }, []);

  return (
    <div className={classes.root}>
      <div className={classes.box}>
        <img className={classes.logo} src={orbitsPurpleLogoSVG} />
        {error && (
          <Alert
            id="login-register-error"
            className={classes.error}
            severity="error"
          >
            {error}
          </Alert>
        )}
        {authHeading && <Typography color="primary">{authHeading}</Typography>}
        <form>
          <Switch>
            <Route path={`${authBasePath}/${en[LOGIN_ROUTE_TEXT]}`}>
              <Login
                login={login}
                gotoForgetPassword={gotoForgetPassword}
                gotoRegister={gotoRegister}
              />
            </Route>
            <Route
              path={`${authBasePath}/${en[NEW_PASSWORD_REQUIRED_ROUTE_TEXT]}`}
            >
              <NewPasswordRequired
                changeTempPassword={changeTempPassword}
                email={email || tempCognitioSession?.getUsername()}
              />
            </Route>
            <Route path={`${authBasePath}/${en[FORGOT_PASSWORD_ROUTE_TEXT]}`}>
              <ForgotPassword
                sendRecoveryEmail={sendRecoveryEmail}
                cancel={_logoutUser}
              />
            </Route>
            <Route
              path={`${authBasePath}/${en[FORGOT_PASSWORD_SUBMIT_ROUTE_TEXT]}`}
            >
              <ForgotPasswordSubmit
                changeForgottenPassword={changeForgottenPassword}
                isPasswordResetRequired={isPasswordResetRequired}
                cancel={_logoutUser}
              />
            </Route>
            <Route path={`${authBasePath}/${en[REGISTER_ROUTE_TEXT]}`}>
              <Register
                createAccountSubmitInfo={createAccountSubmitInfo}
                cancel={_logoutUser}
              />
            </Route>
            <Route
              path={`${authBasePath}/${en[REGISTER_CONFIRMATION_ROUTE_TEXT]}`}
            >
              <RegisterConfirmation
                createAccountSubmitOTP={createAccountSubmitOTP}
                cancel={_logoutUser}
                username={username}
              />
            </Route>
            <Route>
              <Login
                login={login}
                gotoForgetPassword={gotoForgetPassword}
                gotoRegister={gotoRegister}
              />
            </Route>
          </Switch>
        </form>
      </div>
    </div>
  );
}
