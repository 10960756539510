import React, { useState } from "react";

import { Button, TextField, Typography } from "../styledComponents";
import ErrorBoundary from "./ErrorBoundary";
import {
  CANCEL_BUTTON_LABEL,
  CREATE_ACCOUNT_BUTTON_LABEL,
  en,
  INVALID_CODE_LENGTH_ERROR_MSG,
  MISSING_CODE_ERROR_MSG,
  REGISTER_CONFIRMATION_GUIDE_TEXT,
  REGISTER_CONFIRMATION_GUIDE_TEXT_2,
  REGISTER_CONFIRMATION_GUIDE_TEXT_3,
  REGISTER_CONFIRMATION_USERNAME_TEXT,
  REGISTER_HEADING,
  STEP_2_OF_2_LABEL,
} from "../i18n";
import { LoginRegisterClassesType } from "../types";
import { useStyles } from "../LoginRegister";

export type RegisterConfirmationPropsType = {
  createAccountSubmitOTP: (code: string) => Promise<void>;
  cancel: () => void;
  username: string;
};

export default function RegisterConfirmation(
  props: RegisterConfirmationPropsType
) {
  const classes: LoginRegisterClassesType = useStyles();
  const { cancel, username, createAccountSubmitOTP } = props;

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const validateAndSetCode = (value: string) => {
    setCodeError("");
    if (!value) {
      setCodeError(en[MISSING_CODE_ERROR_MSG]);
    }
    if (value.length !== 6) {
      setCodeError(en[INVALID_CODE_LENGTH_ERROR_MSG](value.length));
    }
    setCode(value);
  };
  const updateCode = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    validateAndSetCode(value);
  };

  const _createAccountSubmitOTP = () => {
    validateAndSetCode(code);
    createAccountSubmitOTP(code);
  };

  return (
    <ErrorBoundary>
      <Typography color="primary" variant="h3">
        {en[REGISTER_HEADING]}
      </Typography>
      <Typography color="primary" variant="h4">
        {en[STEP_2_OF_2_LABEL]}
      </Typography>
      <Typography color="primary" variant="body1">
        {en[REGISTER_CONFIRMATION_GUIDE_TEXT]}
      </Typography>
      <Typography color="primary" variant="body1">
        {en[REGISTER_CONFIRMATION_GUIDE_TEXT_2]}
      </Typography>
      <Typography color="primary" variant="body1">
        {en[REGISTER_CONFIRMATION_GUIDE_TEXT_3]}
      </Typography>
      <Typography
        style={{ fontSize: "16px", fontWeight: "bold", marginTop: "24px" }}
        color="primary"
        variant="body1"
      >
        {en[REGISTER_CONFIRMATION_USERNAME_TEXT]}
      </Typography>
      <Typography style={{ fontSize: "24px" }} color="primary" variant="body1">
        {username}
      </Typography>
      <TextField
        autoFocus
        className={codeError ? classes.errorField : classes.field}
        fullWidth
        label="Confirmation code (Required)"
        value={code}
        onChange={updateCode}
        error={Boolean(codeError)}
        helperText={codeError}
        autoComplete="one-time-code"
      />
      <div style={{ float: "right" }}>
        <Button
          className={classes.btn}
          color="primary"
          variant="outlined"
          onClick={cancel}
        >
          {en[CANCEL_BUTTON_LABEL]}
        </Button>
        <Button
          sx={{ marginLeft: 1 }}
          className={classes.btn}
          color="primary"
          variant="contained"
          onClick={_createAccountSubmitOTP}
          onKeyDown={(e) => e.key === "Enter" && _createAccountSubmitOTP()}
        >
          {en[CREATE_ACCOUNT_BUTTON_LABEL]}
        </Button>
      </div>
    </ErrorBoundary>
  );
}
