import React from "react";

import { AppContext } from "../AppContext";
import { OrbitsAppContext } from "../types";

export default function ({ resourceId }: { resourceId: string }) {
  const { getMedia } = React.useContext<OrbitsAppContext>(AppContext);

  const [pdfUrl, setPdfUrl] = React.useState("");

  React.useEffect(() => {
    if (resourceId) {
      const request = getMedia(resourceId);
      (async () => {
        const redirectedUrl = await request.fetch();
        if (!redirectedUrl) {
          return;
        }
        setPdfUrl(redirectedUrl);
      })();
      return () => request.cancel();
    }
    return () => {};
  }, [resourceId]);

  return (
    <object data={pdfUrl} type="application/pdf" width="100%" height="100%">
      <p>
        Your browser doesn't support viwing PDF - here's a link{" "}
        <a href={pdfUrl}>to the PDF!</a>
      </p>
    </object>
  );
}
