import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useHistory } from "react-router-dom";

import OrbotAvatar from "./components/OrbotAvatar";
import InviteFriendModal from "./components/InviteFriendModal";
import { en, INVITE_A_FRIEND_MENU_ITEM, LOGOUT_MENU_ITEM } from "./i18n";
import { AppContext } from "./AppContext";
import { OrbitsAppContext } from "./types";
import { myContentBasePath, uploadsPath } from "./utils";
import contentLibrarySVG from "./images/content-library.svg";
import dashboardSVG from "./images/dashboard.svg";
import GlobalSearch from "./components/GlobalSearch";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#F9F9FB",
  color: theme.palette.text.primary,
}));

interface MenuItemsData {
  icon: React.ReactElement;
  label: string;
  onClick: React.MouseEventHandler<HTMLLIElement> | undefined;
  showOnHeader: boolean;
}

function MenuItemCreator({ icon, label, onClick }: MenuItemsData): JSX.Element {
  return (
    <MenuItem key={label} onClick={onClick} sx={{ height: "42px", py: 1 }}>
      <IconButton
        aria-label={label}
        color="inherit"
        sx={{ mr: 1, height: "16px", width: "16px" }}
      >
        {icon}
      </IconButton>
      <p>{label}</p>
    </MenuItem>
  );
}

export default function Header() {
  const { logoutUser, eventApi } = React.useContext<OrbitsAppContext>(
    AppContext
  );
  const history = useHistory();

  const [
    isInviteFriendModalOpen,
    setIsInviteFriendModalOpen,
  ] = React.useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  // const showNotification: boolean =
  //   getOrbitsConfig().enableNotifications && !hasUserSeenQuizMeTour;

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItemsData: MenuItemsData[] = [
    {
      icon: <img src={dashboardSVG} />,
      label: "Dashboard",
      onClick: () => history.push(myContentBasePath),
      showOnHeader: true,
    },
    // {
    //   icon: <img src={studySpaceSVG} />,
    //   label: "Study Space",
    //   onClick: () => {}, // () => history.push(),
    //   showOnHeader: true,
    // },
    {
      icon: <img src={contentLibrarySVG} />,
      label: "Content Library",
      onClick: () => history.push(uploadsPath),
      showOnHeader: true,
    },
    {
      icon: <PersonAddIcon sx={{ height: "16px", width: "16px" }} />,
      label: en[INVITE_A_FRIEND_MENU_ITEM],
      onClick: () => setIsInviteFriendModalOpen(true),
      showOnHeader: false,
    },
    {
      icon: <LogoutOutlinedIcon sx={{ height: "16px", width: "16px" }} />,
      label: en[LOGOUT_MENU_ITEM],
      onClick: () => logoutUser(),
      showOnHeader: false,
    },
  ];

  // if (showNotification) {
  //   menuItemsData.unshift({
  //     icon: <img src={orbitsIconPurpleSVG} height="16px" width="16px" />,
  //     label: "Live Tour",
  //     onClick: () => setIsQuizMeTourSplashOpen(true),
  //     showOnHeader: true,
  //   });
  // }

  const headerMenuItems = menuItemsData
    .filter((item) => item.showOnHeader)
    .map(MenuItemCreator);

  const menuItems = menuItemsData.map(MenuItemCreator);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {menuItems}
    </Menu>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <StyledAppBar
          position="static"
          variant="outlined"
          elevation={0}
          sx={{ height: "64px" }}
        >
          <Toolbar>
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 1 }}
            >
              <OrbotAvatar />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }}>
              <GlobalSearch />
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", lg: "flex" } }}>
              {headerMenuItems}
            </Box>
            <Box>
              <IconButton
                size="large"
                aria-label="show menu"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
          </Toolbar>
        </StyledAppBar>
        {renderMenu}
      </Box>
      <InviteFriendModal
        open={isInviteFriendModalOpen}
        close={() => setIsInviteFriendModalOpen(false)}
        eventApi={eventApi}
      />
    </>
  );
}
