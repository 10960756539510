import React from "react";
import { en, ERROR_BOUNDARY_MSG } from "../i18n";

export default class ErrorBoundary extends React.Component<
  {},
  { hasError: boolean }
> {
  public readonly state = { hasError: false };

  constructor(props: object) {
    super(props);
  }

  static getDerivedStateFromError(error: unknown) {
    console.log(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorinfo: unknown) {
    // TODO: log the error to an error reporting service
    console.log(error, errorinfo);
  }

  render() {
    if (this.state.hasError) {
      // TODO: render custom fallback UI
      return <h1>{en[ERROR_BOUNDARY_MSG]}</h1>;
    }

    return this.props.children;
  }
}
